// @ts-ignore
import v1 from 'uuid/v1';
import { Mutex } from 'async-mutex';
import { IGenericObject, IStorageEntry } from '../constants/types';
import { postsAddItem } from '../redux/actions/Posts';
import HTMLEntities from '../VO/HTMLEntities';

class Employee implements IStorageEntry {
	static type: string = 'employee';

	static mutex = new Mutex();

	id: string | number;

	private title: string;

	private image: string | number;

	private phone: string;

	private email: string;

	private position: string;

	private positionSpecifics: string;

	private department: string;

	loadedTimeDate: number = new Date().valueOf();

	constructor(
		id: string | number | null,
		title: string = '',
		phone: string = '',
		email: string = '',
		position: string = '',
		positionSpecifics: string = '',
		department: string = '',
		image: string | number = 0
	) {
		this.id = id || v1();
		this.title = title;
		this.image = image;
		this.phone = phone;
		this.email = email;
		this.position = position;
		this.positionSpecifics = positionSpecifics;
		this.department = department;
	}

	storageId() {
		return `${Employee.type}:${this.id}`;
	}

	value(): {
		storageId: string;
		id: string | number;
		title: string;
		image: string | number;
		phone: string;
		email: string;
		position: string;
		positionSpecifics: string;
		department: string;
		loadedTime: number;
	} {
		return {
			storageId: this.storageId(),
			id: this.id,
			title: this.title,
			image: this.image,
			phone: this.phone,
			email: this.email,
			position: this.position,
			positionSpecifics: this.positionSpecifics,
			department: this.department,
			loadedTime: this.loadedTimeDate,
		};
	}

	loadedTime(): number {
		return this.loadedTimeDate;
	}

	// @ts-ignore
	fromBackend(data: IGenericObject): Employee {
		this.id = data.id;
		this.title = data.title ? new HTMLEntities(data.title.rendered).decoded : '';
		this.image = data.featured_media;
		this.phone = data.phone;
		this.email = data.email;
		this.position = data.position && data.position.length ? `${data.position[0]}` : '0';
		this.positionSpecifics = data.position_name || '';
		this.department = data.department && data.department.length ? `${data.department[0]}` : '0';
		this.loadedTimeDate = new Date().valueOf();

		return this;
	}

	// @ts-ignore
	fromStorage(storage: IGenericObject): Employee {
		const data = storage.getState().Posts[this.storageId()];

		if (!data) {
			this.loadedTimeDate = 0;

			return this;
		}

		this.id = data.id;
		this.title = data.title;
		this.image = data.image;
		this.phone = data.phone;
		this.email = data.email;
		this.position = data.position;
		this.positionSpecifics = data.positionSpecifics;
		this.department = data.department;
		this.loadedTimeDate = data.loadedTime;

		return this;
	}

	// @ts-ignore
	saveToStorage(storage: IGenericObject): Employee {
		storage.dispatch(postsAddItem(this.value()));

		return this;
	}
}

export default Employee;
