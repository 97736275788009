import { Helmet } from 'react-helmet';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import HeadElement, { IHeadElementValue } from '../generics/HeadElement';
import ResourceCache from '../Entities/ResourceCache';
import StoragePage from '../Storage/Page';
import HttpPage from '../VO/Http/Page';
import { resolveClient } from '../constants/httpClients';
import { IGenericObject } from '../constants/types';

interface IHeads {}

const resourcesInstance = new ResourceCache();

// eslint-disable-next-line no-empty-pattern
const Heads = ({}: IHeads): JSX.Element => {
	const [elements, setElements] = useState<IHeadElementValue[]>([]);
	const location = useLocation();

	useEffect(() => {
		const handler = async () => {
			const { head } = await resourcesInstance.requestItem(
				// @ts-ignore
				new StoragePage(window.location.href),
				new HttpPage(resolveClient()),
				StoragePage.mutex
			);

			setElements(head.map((item: HeadElement) => item.toComponent()));
		};

		handler();
	}, [location]);

	return (
		<Helmet>
			{elements.map((element) => {
				const object: IGenericObject = {};

				element.attributes.forEach((attribute) => {
					object[attribute.property] = attribute.value;
				});

				return React.createElement(element.tag, { id: element.id, ...object }, element.content);
			})}
		</Helmet>
	);
};

export default Heads;
