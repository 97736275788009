class OptionalGetter {
	private readonly callback;

	private readonly defaultValue: any;

	constructor(callback = () => {}, defaultValue: any = '') {
		this.callback = callback;
		this.defaultValue = defaultValue;
	}

	get value() {
		try {
			return this.callback();
		} catch (e) {
			return this.defaultValue;
		}
	}

	get trueValue() {
		return this.value || this.defaultValue;
	}
}

export default OptionalGetter;
