class CacheTime {
	#time = 0;

	get time() {
		return this.#time;
	}

	isValid = (fromTime: number) => {
		if (Object.prototype.hasOwnProperty.call(window, 'cacheTime')) {
			// @ts-ignore
			this.#time = window.cacheTime || 0;
		}

		return fromTime + this.time > new Date().valueOf();
	};

	addMilliseconds = (value: number): CacheTime => {
		this.#time += value;

		return this;
	};

	addSeconds = (value: number): CacheTime => {
		return this.addMilliseconds(value * 1000);
	};

	addMinutes = (value: number): CacheTime => {
		return this.addSeconds(value * 60);
	};

	addHours = (value: number): CacheTime => {
		return this.addMinutes(value * 60);
	};

	addDays = (value: number): CacheTime => {
		return this.addHours(value * 24);
	};

	addMonths = (value: number): CacheTime => {
		return this.addDays(value * 30);
	};

	addYears = (value: number): CacheTime => {
		return this.addMonths(value * 12);
	};
}

export default CacheTime;
