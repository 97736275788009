import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
// @ts-ignore
import { ReactComponent as Logo } from '../../img/logo-small.svg';
import { PrimaryColors, PrimaryRGBColors } from '../constants/colors';
import { Metric } from '../constants/fonts';

interface ILoading {
	className?: string;
}

const SSpan = styled.span`
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 30px;
	flex-grow: 1;
`;

const logoLetterTopAnimation = keyframes`
  0% {
    transform: translateY(0px);
  }

  15% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(-15px);
  }

  35% {
    transform: translateY(-15px);
  }

  45% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(0px);
  }
`;

const logoLetterBottomAnimation = keyframes`
  0% {
    transform: translateY(0px);
  }

  15% {
    transform: translateY(0px);
  }

  25% {
    transform: translateY(12px);
  }

  35% {
    transform: translateY(12px);
  }

  45% {
    transform: translateY(0px);
  }

  100% {
    transform: translateY(0px);
  }
`;

const animationTime = '4s';

const STextsWrapper = styled.div`
	display: flex;
	flex-flow: column;
	align-items: center;
	justify-content: center;
	position: relative;
	width: 150px;
	height: 150px;
	box-sizing: content-box;
	overflow: visible;
	padding-bottom: 30px;
	padding-top: 40px;
`;

const loadingTextAnimation = keyframes`
  0% {
    transform: scale(0.6);
    opacity: 0;
  }

  20% {
    transform: scale(0.6);
    opacity: 0;
  }

  25% {
    transform: scale(1);
    opacity: 1;
  }

  35% {
    transform: scale(1);
    opacity: 1;
  }

  40% {
    transform: scale(0.6);
    opacity: 0;
  }

  100% {
    transform: scale(0.6);
    opacity: 0;
  }
`;

const SLoadingText = styled.span`
	position: absolute;
	height: 37px;
	top: 85px;
	box-sizing: border-box;
	left: 0;
	letter-spacing: 4px;
	font-size: 20px;
	text-transform: uppercase;
	text-align: center;
	right: 0;
	color: ${PrimaryColors.red};
	font-family: ${Metric.regular.name};
	-webkit-font-smoothing: antialiased;
	transform: scale(0.6);
	opacity: 0;

	animation-duration: ${animationTime};
	animation-name: ${loadingTextAnimation};
	animation-direction: normal;
	animation-fill-mode: both;
	animation-iteration-count: infinite;
`;

const SLogo = styled(Logo)`
	overflow: visible;

	.logo-small_svg__letter-path {
		fill: rgba(${PrimaryRGBColors.grey(0.25)});

		&--is-top {
			animation-duration: ${animationTime};
			animation-name: ${logoLetterTopAnimation};
			animation-direction: normal;
			animation-fill-mode: both;
			animation-iteration-count: infinite;
		}

		&--is-bottom {
			animation-duration: ${animationTime};
			animation-name: ${logoLetterBottomAnimation};
			animation-direction: normal;
			animation-fill-mode: both;
			animation-iteration-count: infinite;
		}
	}

	.logo-small_svg__sign-path {
		fill: rgba(${PrimaryRGBColors.white(0.6)});

		animation-duration: ${animationTime};
		animation-name: ${logoLetterBottomAnimation};
		animation-direction: normal;
		animation-fill-mode: both;
		animation-iteration-count: infinite;
	}
`;

// eslint-disable-next-line no-empty-pattern
const Loading = ({ className = '' }: ILoading): JSX.Element => {
	return (
		<SSpan className={className}>
			<STextsWrapper>
				<SLogo />
				<SLoadingText>Loading</SLoadingText>
			</STextsWrapper>
		</SSpan>
	);
};

export default Loading;
