import styled from '@emotion/styled';
import { Link, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import debounce from 'lodash/debounce';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { ILink } from '../constants/types';
import Wrapper from './Wrapper';
import DefaultBoxShadow from './DefaultBoxShadow';
import SiteRouter from '../SiteRouter';
// @ts-ignore
import { ReactComponent as Logo } from '../../img/logo.svg';
// @ts-ignore
import { ReactComponent as SmallLogo } from '../../img/logo-small.svg';
// @ts-ignore
import { ReactComponent as Search } from '../../img/search.svg';
import UnderlinedLink from './UnderlinedLink';
import { PrimaryColors } from '../constants/colors';
import UnstyledButton from './UnstyledButton';
import { xl, md, MediaQuery } from '../constants/breakpoints';
import useWindowSize from '../constants/useWindowSize';
import BurgerButton from './BurgerButton';
import { headerHeight, heightPoint } from '../constants/sizes';
import InputBox from './Search/InputBox';
import LinkHelpers from '../VO/LinkHelpers';

interface IHeader {
	links: ILink[];
}

const SHeader = styled.header`
	position: relative;
	z-index: 6;
	height: 100px;

	${new MediaQuery().md(`
    position: sticky;
    top: 0;
    display: flex;
    max-width: 100vw;
    height: 80px;
  `).value}

	@media (max-width: 782px) {
		top: ${document.body?.classList.contains('admin-bar') ? '46px' : 0};
	}
`;

const SSectionWrapper = styled(Wrapper)`
	box-shadow: 0 5px 24px rgba(5, 0, 56, 0.1);
	transition: 300ms all ease;
	background: ${PrimaryColors.white};
	height: 100%;
`;

const SLogo = styled(Link)`
	display: flex;
	height: 40px;
	position: relative;
	z-index: 4;

	svg {
		width: 330px;
	}

	${new MediaQuery().xl(`
    margin-right: auto;
    height: 100%;
    max-height: 58px;
    max-width: 80px;
  `).value}
`;

const SLogoText = styled.span`
	opacity: 0;
	position: absolute;
`;

const SSmallLogo = styled(SmallLogo)`
	display: flex;
	width: 100%;

	${new MediaQuery().xl(`
    width: 72px !important;
    height: 57px;
  `).value}
`;

const SLink = styled(UnderlinedLink)`
	letter-spacing: -0.3px;

	&:after {
		bottom: -5px;

		${new MediaQuery().md(`
      bottom: 7px;
    `).value}
	}

	${new MediaQuery().md(`
    justify-self: center;
    align-self: center;
    padding: 18px 0 12px;
  `).value}
`;

const SWrapper = styled(Wrapper)`
	flex-flow: row;
	align-items: center;
	padding-top: 30px;
	padding-bottom: 28px;
	position: relative;
	height: 100%;

	&:after {
		content: '';
		position: absolute;
		z-index: 3;
		background: ${PrimaryColors.white};
		top: 0;
		bottom: 0;
		left: 0;
		width: 100%;
	}

	${new MediaQuery().md(`
    padding-top: 10px;
    padding-bottom: 10px;
  `).value}
`;

const SMenuGrid = styled.div<{ isActive: boolean; additionalOffset: number }>`
	margin-left: auto;

	padding-top: 4px;
	transition: 300ms all ease;
	background: ${PrimaryColors.white};
	position: relative;
	z-index: 4;
	overflow-x: auto;
	flex-shrink: 1;
	padding-left: 20px;
	padding-bottom: 7px;
	scrollbar-width: none;

	&::-webkit-scrollbar {
		display: none;
	}

	${(props) => {
		const offsetHeight = props.isActive ? 0 : `calc(-100% - var(${headerHeight}))`;

		return new MediaQuery().md(`
      position: absolute;
      height: calc(100 * var(${heightPoint}) - var(${headerHeight}) - ${props.additionalOffset}px);
      top: var(${headerHeight});
      transform: translateY(${offsetHeight});
      z-index: -1;
      padding: 20px;
      left: -10px;
      right: -10px;
      overflow-y: auto;
  `).value;
	}}
`;

const SLinksWrapper = styled.div`
	display: grid;
	gap: 20px;
	grid-auto-flow: column;
	grid-auto-columns: max-content;

	${new MediaQuery().md(`
    grid-auto-flow: row;
    grid-auto-rows: max-content;
    align-content: center;
    justify-content: center;
    gap: 15px;
    min-height: 100%;
  `).value}
`;

const SButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;
	//position: relative;
	z-index: 4;
	flex-shrink: 0;

	${new MediaQuery().md(`
    margin-right: -20px;
  `).value}
`;

const SButton = styled(UnstyledButton)<{ isSearchActive: boolean }>`
	line-height: 1;
	margin-left: 50px;
	padding: 10px 1px 12px;
	opacity: ${(props) => (props.isSearchActive ? 0 : 1)};
`;

const SSearch = styled(Search)`
	color: ${PrimaryColors.red};
	height: 20px;
	margin-right: 16px;

	${new MediaQuery().md(`
    margin-right: 19px;
  `).value}
`;

const SSearchText = styled.span`
	${new MediaQuery().md('display: none;').value};
`;

const SActiveSearch = styled(UnstyledButton)<{ isSearchActive: boolean }>`
	position: absolute;
	right: -10px;
	top: 0;
	bottom: 0;
	width: auto;
	min-width: 0;
	background: ${PrimaryColors.grey2};
	pointer-events: ${(props) => (props.isSearchActive ? 'all' : 'none')};
	opacity: ${(props) => (props.isSearchActive ? 1 : 0)};

	${new MediaQuery().md(`
    right: 50px;
  `).value};
`;

const SActiveText = styled.span`
	transform: translateY(-1px);
`;

const SActiveBurger = styled.span<{ hasMargin: boolean }>`
	position: relative;
	height: 26px;
	width: 26px;
	display: flex;
	transform: rotate(45deg);
	margin-left: ${(props) => (props.hasMargin ? '2px' : 0)};
	margin-right: ${(props) => (props.hasMargin ? '7px' : 0)};

	&:after,
	&:before {
		content: '';
		position: absolute;
		background: ${PrimaryColors.black};
		transition: 300ms all ease;
	}

	&:after {
		top: 0;
		left: 50%;
		height: 100%;
		width: 2px;
		transform: translateX(-50%);
	}

	&:before {
		top: 50%;
		left: 0;
		height: 2px;
		width: 100%;
		transform: translateY(-50%);
	}
`;

const SSearchBoxWrapper = styled(DefaultBoxShadow)<{ isSearchActive: boolean }>`
	position: absolute;
	top: var(${headerHeight});
	background: ${PrimaryColors.white};
	display: flex;
	left: 0;
	right: 0;
	padding-top: 64px;
	padding-bottom: 48px;
	z-index: -1;
	transition: 300ms all ease;
	transform: translateY(${(props) => (props.isSearchActive ? 0 : 'calc(-100% - 40px)')});
`;

const SBurgerButton = styled(BurgerButton)`
	display: none;

	${new MediaQuery().md(`
    display: flex;
  `).value};
`;

const Header = ({ links }: IHeader): JSX.Element => {
	const { width } = useWindowSize();
	const navigate = useNavigate();
	const [isSearchActive, setIsSearchActive] = useState(false);
	const [searchText, setSearchText] = useState('');
	const [isMenuActive, setIsMenuActive] = useState(false);
	const mainElement = document.getElementById('header-menu-wrapper') || document.body;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const resizeHandler = useCallback(
		debounce(() => {
			const element = document.getElementById('primary-header');

			if (!element) {
				return;
			}

			document.documentElement.style.setProperty(headerHeight, `${element.clientHeight}px`);
		}, 300),
		[]
	);

	useEffect(() => {
		const RO = new ResizeObserver(() => {
			resizeHandler();
		});

		// @ts-ignore
		RO.observe(document.getElementById('primary-header'));

		return () => {
			RO.disconnect();
		};
	}, [resizeHandler]);

	return (
		<SHeader id="primary-header">
			<SSectionWrapper isFluid as="section">
				<SWrapper>
					<SLogo
						to={SiteRouter.home()}
						onClick={() => {
							setIsMenuActive(false);
							enableBodyScroll(mainElement);
						}}
					>
						<SLogoText>Site Logo</SLogoText>
						{width > xl ? <Logo /> : <SSmallLogo />}
					</SLogo>

					<SMenuGrid
						isActive={isMenuActive}
						additionalOffset={document.body?.classList.contains('admin-bar') && width <= 782 ? 46 : 0}
						id="header-menu-wrapper"
					>
						<SLinksWrapper>
							{links.map((link) => {
								const value = link.value();

								return (
									<SLink
										key={value.id}
										to={!LinkHelpers.isRelativeLink(value.url) ? value.url.replace(/http(s|):/gi, '') : value.url}
										end
										target={!LinkHelpers.isRelativeLink(value.url) ? '_blank' : '_self'}
										onClick={() => {
											setIsMenuActive(false);
											enableBodyScroll(mainElement);
										}}
									>
										{value.title}
									</SLink>
								);
							})}
						</SLinksWrapper>
					</SMenuGrid>

					<SButtonsWrapper>
						<SButton
							isSearchActive={isSearchActive}
							onClick={() => {
								setIsSearchActive(true);
								setIsMenuActive(false);
								enableBodyScroll(mainElement);
							}}
						>
							<SSearch />
							<SSearchText>Søk</SSearchText>
						</SButton>

						<SActiveSearch isSearchActive={isSearchActive} onClick={() => setIsSearchActive(false)}>
							<SActiveBurger hasMargin={width > md} />
							{width > md && <SActiveText>Lukk</SActiveText>}
						</SActiveSearch>

						<SBurgerButton
							isActive={isMenuActive}
							onClick={() => {
								setIsMenuActive(!isMenuActive);

								const method = isMenuActive ? enableBodyScroll : disableBodyScroll;

								method(mainElement);

								if (isMenuActive) {
									return;
								}

								setIsSearchActive(false);
							}}
						/>
					</SButtonsWrapper>
				</SWrapper>
			</SSectionWrapper>

			<SSearchBoxWrapper isSearchActive={isSearchActive}>
				<Wrapper isNarrow>
					<InputBox
						searchText={searchText}
						onSearch={(text) => {
							if (!text) {
								return;
							}

							setSearchText(text);
							navigate(`/?s=${text}`);
							setIsSearchActive(false);
							setTimeout(() => {
								setSearchText('');
							}, 400);
						}}
						isDisabled={false}
					/>
				</Wrapper>
			</SSearchBoxWrapper>
		</SHeader>
	);
};

export default Header;
