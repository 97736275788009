import React from 'react';
import { IGenericObject, ILink } from '../constants/types';
import HeaderView from '../views/Header';
import DefaultLink from '../generics/DefaultLink';
import OptionalGetter from '../VO/OptionalGetter';
import LinkHelpers from '../VO/LinkHelpers';

interface IHeaderProps {}

interface IHeaderState {
	links: ILink[];
}

class Header extends React.Component<IHeaderProps, IHeaderState> {
	state = {
		links: new OptionalGetter(
			() =>
				// @ts-ignore
				window.themeSettings.header.menu.items.map(
					(linkObject: IGenericObject) =>
						new DefaultLink(linkObject.title, LinkHelpers.toRelative(linkObject.url), linkObject.ID)
				),
			[]
		).value,
	};

	render(): JSX.Element {
		return <HeaderView links={this.state.links} />;
	}
}

export default Header;
