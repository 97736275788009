// eslint-disable-next-line import/prefer-default-export
export enum PrimaryColors {
	darkRed = '#C8102E',
	white = '#FFFFFF',
	black = '#1D1D1D',
	red = '#E4002B',
	grey = '#75787B',
	grey1 = '#D9D9D9',
	grey2 = '#E0E3E6',
	light = '#ECEFF2',
	blue = '#004D7E',
	black1 = '#111111',
	black2 = '#000000',
}

export const PrimaryRGBColors = {
	darkRed: (opacity: number = 1) => `200, 16, 46, ${opacity}`,
	white: (opacity: number = 1) => `255, 255, 255, ${opacity}`,
	black: (opacity: number = 1) => `29, 29, 29, ${opacity}`,
	red: (opacity: number = 1) => `228, 0, 43, ${opacity}`,
	grey: (opacity: number = 1) => `117, 120, 123, ${opacity}`,
	grey1: (opacity: number = 1) => `217, 217, 217, ${opacity}`,
	light: (opacity: number = 1) => `236, 239, 242, ${opacity}`,
	blue: (opacity: number = 1) => `0, 77, 126, ${opacity}`,
	black1: (opacity: number = 1) => `17, 17, 17, ${opacity}`,
	black2: (opacity: number = 1) => `0, 0, 0, ${opacity}`,
};
