import { IDepartment, ILink } from '../constants/types';

class Department implements IDepartment {
	public description: string;

	public link: ILink;

	constructor(description: string, link: ILink) {
		this.description = description;
		this.link = link;
	}
}

export default Department;
