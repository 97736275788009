import { IMediaSizes } from '../Storage/Media';

export const defaultPadding: number = 10;
export const defaultWrapper: number = 1392;
export const narrowWrapper: number = 920;

export const headerHeight: string = '--header-height';
export const footerHeight: string = '--footer-height';
export const heightPoint: string = '--height-point';

export const articleGridSizes: { size: string; screenWidth: string; source: keyof IMediaSizes }[] = [
	{
		size: '355',
		screenWidth: '375',
		source: '375x',
	},
	{
		size: '748',
		screenWidth: '767',
		source: '768x',
	},
	{
		size: '448',
		screenWidth: '',
		source: '448x290',
	},
];
