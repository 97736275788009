import styled from '@emotion/styled';
import { PrimaryColors } from '../constants/colors';

interface IDefaultBoxShadow {
	children: JSX.Element | JSX.Element[];
	as?: string;
	id?: string;
	className?: string;
	hasDarkenTransition?: boolean;
}

const SDefaultBoxShadow = styled.div<{ as: string; hasDarkenTransition: boolean }>`
	background: ${PrimaryColors.white};
	box-shadow: 0 5px 24px rgba(5, 0, 56, ${(props) => (props.hasDarkenTransition ? 0.2 : 0.1)});
	transition: 300ms all ease;
`;

const DefaultBoxShadow = ({
	children,
	as = 'div',
	id = `id-${Math.random()}`,
	className = '',
	hasDarkenTransition = false,
}: IDefaultBoxShadow): JSX.Element => {
	return (
		// @ts-ignore
		<SDefaultBoxShadow as={as} className={className} hasDarkenTransition={hasDarkenTransition} id={id}>
			{children}
		</SDefaultBoxShadow>
	);
};

export default DefaultBoxShadow;
