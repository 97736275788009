// @ts-ignore
import v1 from 'uuid/v1';
import { ILink } from '../constants/types';

class DefaultLink implements ILink {
	#id: string;

	#title: string;

	#url: string;

	constructor(title: string, url: string, id?: string) {
		this.#title = title;
		this.#url = url;
		this.#id = id || v1();
	}

	public value(): { url: string; title: string; id: string } {
		return { id: this.#id, title: this.#title, url: this.#url };
	}
}

export default DefaultLink;
