// @ts-ignore
import v1 from 'uuid/v1';
import { Mutex } from 'async-mutex';
import { IGenericObject, IMapLocation, IStorageEntry } from '../constants/types';
import { postsAddItem } from '../redux/actions/Posts';
import OptionalGetter from '../VO/OptionalGetter';
import HTMLEntities from '../VO/HTMLEntities';

class Department implements IStorageEntry {
	static type: string = 'department';

	static mutex = new Mutex();

	id: string | number;

	private name: string;

	private slug: string;

	private description: string;

	private address: string;

	private addressText: string;

	private icon: string;

	private coordinates: IMapLocation;

	loadedTimeDate: number = new Date().valueOf();

	constructor(
		id: string | number | null = null,
		coordinates: IMapLocation = { lat: 0, lng: 0 },
		name: string = '',
		description: string = '',
		slug: string = '',
		address: string = '',
		addressText: string = '',
		icon: string = ''
	) {
		this.id = id || v1();
		this.name = name;
		this.slug = slug;
		this.address = address;
		this.addressText = addressText;
		this.icon = icon;
		this.description = description;
		this.coordinates = coordinates;
	}

	storageId() {
		return `${Department.type}:${this.id}`;
	}

	value(): {
		storageId: string;
		id: string | number;
		name: string;
		slug: string;
		description: string;
		address: string;
		addressText: string;
		icon: string;
		coordinates: IMapLocation;
		loadedTime: number;
	} {
		return {
			storageId: this.storageId(),
			id: this.id,
			name: this.name,
			slug: this.slug,
			description: this.description,
			address: this.address,
			addressText: this.addressText,
			icon: this.icon,
			coordinates: this.coordinates,
			loadedTime: this.loadedTimeDate,
		};
	}

	loadedTime(): number {
		return this.loadedTimeDate;
	}

	// @ts-ignore
	fromBackend(data: IGenericObject): Department {
		this.id = data.id;
		this.name = new HTMLEntities(data.name || '').decoded;
		this.slug = data.slug || '';
		this.description = new HTMLEntities(data.description || '').decoded;
		this.address = new HTMLEntities(data.address || '').decoded;
		this.addressText = HTMLEntities.processNewLinesToBr(data.address_title || '');
		this.icon = data.icon || '';
		this.coordinates = new OptionalGetter(() => ({ lat: data.map.latitude, lng: data.map.longitude }), {
			lat: 0,
			lng: 0,
		}).value;
		this.loadedTimeDate = new Date().valueOf();

		return this;
	}

	// @ts-ignore
	fromStorage(storage: IGenericObject): Department {
		const data = storage.getState().Posts[this.storageId()];

		if (!data) {
			this.loadedTimeDate = 0;

			return this;
		}

		this.id = data.id;
		this.name = data.name;
		this.slug = data.slug;
		this.description = data.description;
		this.address = data.address;
		this.addressText = data.addressText;
		this.icon = data.icon;
		this.coordinates = data.coordinates;
		this.loadedTimeDate = data.loadedTime;

		return this;
	}

	// @ts-ignore
	saveToStorage(storage: IGenericObject): Department {
		storage.dispatch(postsAddItem(this.value()));

		return this;
	}
}

export default Department;
