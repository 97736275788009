// eslint-disable-next-line import/prefer-default-export
export const Metric = {
	regular: {
		fontFamily: 'Metric-Regular',
		name: `'Metric-Regular', sans-serif`,
		// @ts-ignore
		// eslint-disable-next-line no-underscore-dangle
		src: `url("${window.__PUBLIC_PATH__}/fonts/metric-regular.ttf")`,
		fontDisplay: 'swap',
	},
	medium: {
		fontFamily: 'Metric-Medium',
		name: `'Metric-Medium', sans-serif`,
		// @ts-ignore
		// eslint-disable-next-line no-underscore-dangle
		src: `url("${window.__PUBLIC_PATH__}/fonts/metric-medium.ttf")`,
		fontDisplay: 'swap',
	},
	semiBold: {
		fontFamily: 'Metric-Semi-Bold',
		name: `'Metric-Semi-Bold', sans-serif`,
		// @ts-ignore
		// eslint-disable-next-line no-underscore-dangle
		src: `url("${window.__PUBLIC_PATH__}/fonts/metric-semibold.ttf")`,
		fontDisplay: 'swap',
	},
};
