import styled from '@emotion/styled';
import Wrapper from './Wrapper';
// @ts-ignore
import { ReactComponent as Icon } from '../../img/not-found.svg';
import { PrimaryColors } from '../constants/colors';
import { Metric } from '../constants/fonts';
import { MediaQuery } from '../constants/breakpoints';

interface INotFound {}

const SWrapper = styled(Wrapper)`
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	padding: 40px 20px;
`;

const SIcon = styled(Icon)`
	color: ${PrimaryColors.red};
	max-width: 470px;
`;

const STitle = styled.h1`
	font-family: ${Metric.semiBold.name};
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-weight: 400;
	font-size: 32px;
	color: ${PrimaryColors.black};
	line-height: 1.25;
	margin-bottom: 0;
	margin-top: 25px;

	${new MediaQuery().md(`font-size: 28px;`).value}
`;

// eslint-disable-next-line no-empty-pattern
const NotFound = ({}: INotFound): JSX.Element => {
	return (
		<SWrapper>
			<SIcon />
			<STitle>Oi, vi fant visst ikke det du leter etter…</STitle>
		</SWrapper>
	);
};

export default NotFound;
