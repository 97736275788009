// @ts-ignore
import v1 from 'uuid/v1';
import { Mutex } from 'async-mutex';
import { IGenericObject, IStorageEntry } from '../constants/types';
import { postsAddItem } from '../redux/actions/Posts';
import HTMLEntities from '../VO/HTMLEntities';

class Testimonial implements IStorageEntry {
	static type: string = 'testimonial';

	static mutex = new Mutex();

	id: string | number;

	private title: string;

	private iconId: string | number;

	private description: string;

	private jobTitle: string;

	loadedTimeDate: number = new Date().valueOf();

	constructor(
		id: string | number | null,
		title: string = '',
		description: string = '',
		jobTitle: string = '',
		iconId: string | number = 0
	) {
		this.id = id || v1();
		this.title = title;
		this.iconId = iconId;
		this.description = description;
		this.jobTitle = jobTitle;
	}

	storageId() {
		return `${Testimonial.type}:${this.id}`;
	}

	value(): {
		storageId: string;
		id: string | number;
		title: string;
		iconId: string | number;
		description: string;
		jobTitle: string;
		loadedTime: number;
	} {
		return {
			storageId: this.storageId(),
			id: this.id,
			title: this.title,
			iconId: this.iconId,
			description: this.description,
			jobTitle: this.jobTitle,
			loadedTime: this.loadedTimeDate,
		};
	}

	loadedTime(): number {
		return this.loadedTimeDate;
	}

	// @ts-ignore
	fromBackend(data: IGenericObject): Testimonial {
		this.id = data.id;
		this.title = data.title ? new HTMLEntities(data.title.rendered).decoded : '';
		this.iconId = data.icon ? data.icon.ID : 0;
		this.description = new HTMLEntities(data.quote).decoded;
		this.jobTitle = new HTMLEntities(data.job_title).decoded;
		this.loadedTimeDate = new Date().valueOf();

		return this;
	}

	// @ts-ignore
	fromStorage(storage: IGenericObject): Testimonial {
		const data = storage.getState().Posts[this.storageId()];

		if (!data) {
			this.loadedTimeDate = 0;

			return this;
		}

		this.id = data.id;
		this.title = data.title;
		this.iconId = data.iconId;
		this.description = data.description;
		this.jobTitle = data.jobTitle;
		this.loadedTimeDate = data.loadedTime;

		return this;
	}

	// @ts-ignore
	saveToStorage(storage: IGenericObject): Testimonial {
		storage.dispatch(postsAddItem(this.value()));

		return this;
	}
}

export default Testimonial;
