import { decode, encode } from 'html-entities';

class HTMLEntities {
	private readonly string: string;

	static processNewLinesToBr(string: string, _replaceSymbols: string | null = null) {
		const replaceSymbols = _replaceSymbols || '\r\n';
		const replaceTo = '<br />';

		return string.split(replaceSymbols).join(replaceTo);
	}

	constructor(string: string) {
		this.string = string;
	}

	get decoded(): string {
		return decode(this.string);
	}

	get encoded(): string {
		return encode(this.string);
	}
}

export default HTMLEntities;
