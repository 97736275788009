import { IGenericObject } from '../constants/types';

export interface IHeadElementValue {
	id: string;
	tag: string;
	attributes: { property: string; value: string }[];
	content: string;
}

class HeadElement {
	private key: string;

	private value: string | IGenericObject;

	constructor(key: string, value: string | IGenericObject) {
		this.key = key;
		this.value = value;
	}

	private resolveId = (): string => {
		return [this.key, this.resolveTag()].join('_');
	};

	private resolveTag = (): string => {
		const dict: IGenericObject = {
			title: 'title',
			schema: 'script',
			default: 'meta',
		};

		return dict[this.key] || dict.default;
	};

	private resolveContent = (): string => {
		const dict: IGenericObject = {
			title: this.value,
			schema: JSON.stringify(this.value),
			default: '',
		};

		return dict[this.key] || dict.default;
	};

	private resolveAttributes = (): { property: string; value: string }[] => {
		const dict: IGenericObject = {
			title: [],
			schema: [{ property: 'type', value: 'application/ld+json' }],
			robots: [
				{ property: 'name', value: 'robots' },
				{ property: 'content', value: Object.values(this.value).join(', ') },
			],
			twitter_card: [
				{ property: 'name', value: this.key.split('_').join(':') },
				{ property: 'content', value: JSON.stringify(this.value) },
			],
			default: [
				{ property: 'property', value: this.key.split('_').join(':') },
				{ property: 'content', value: JSON.stringify(this.value) },
			],
		};

		return dict[this.key] || dict.default;
	};

	toComponent = (): IHeadElementValue => {
		return {
			id: this.resolveId(),
			tag: this.resolveTag(),
			attributes: this.resolveAttributes(),
			content: this.resolveContent(),
		};
	};
}

export default HeadElement;
