// @ts-ignore
import v1 from 'uuid/v1';
import { Mutex } from 'async-mutex';
import { EPostGridTypes, IGenericObject, IStorageEntry } from '../constants/types';
import { postsAddItem } from '../redux/actions/Posts';
import OptionalGetter from '../VO/OptionalGetter';
import HTMLEntities from '../VO/HTMLEntities';

class Service implements IStorageEntry {
	static type: string = 'service';

	static BETypes: { wood: string; man: string; drops: string } = {
		wood: EPostGridTypes.square,
		man: EPostGridTypes.construction,
		drops: EPostGridTypes.drops,
	};

	static mutex = new Mutex();

	id: string | number;

	private title: string;

	private slug: string;

	private description: string;

	private content: string;

	private icon: string;

	private image: string | number;

	private services: string[] | number[];

	private employees: string[] | number[];

	private hasVideo: boolean;

	private hasForm: boolean;

	loadedTimeDate: number = new Date().valueOf();

	constructor(
		id: string | number | null,
		title: string = '',
		description: string = '',
		slug: string = '',
		content: string = '',
		icon: string = '',
		image: string | number = '',
		services: string[] | number[] = [],
		employees: string[] | number[] = [],
		hasForm: boolean = false,
		hasVideo: boolean = false
	) {
		this.id = id || v1();
		this.title = title;
		this.slug = slug;
		this.content = content;
		this.icon = icon;
		this.image = image;
		this.description = description;
		this.services = services;
		this.employees = employees;
		this.hasVideo = hasVideo;
		this.hasForm = hasForm;
	}

	storageId() {
		return `${Service.type}:${this.id}`;
	}

	value(): {
		storageId: string;
		id: string | number;
		title: string;
		slug: string;
		description: string;
		content: string;
		icon: string;
		image: string | number;
		services: string[] | number[];
		employees: string[] | number[];
		hasVideo: boolean;
		hasForm: boolean;
		loadedTime: number;
	} {
		return {
			storageId: this.storageId(),
			id: this.id,
			title: this.title,
			slug: this.slug,
			description: this.description,
			content: this.content,
			icon: this.icon,
			image: this.image,
			services: this.services,
			employees: this.employees,
			hasVideo: this.hasVideo,
			hasForm: this.hasForm,
			loadedTime: this.loadedTimeDate,
		};
	}

	loadedTime(): number {
		return this.loadedTimeDate;
	}

	// @ts-ignore
	fromBackend(data: IGenericObject): Service {
		this.id = data.id;
		this.title = new OptionalGetter(() => new HTMLEntities(data.title.rendered).decoded, '').value;
		this.slug = data.slug || '';
		this.description = new OptionalGetter(() => data.excerpt.rendered, '').value;
		this.content = new OptionalGetter(() => data.custom_content.rendered, '').value;
		// @ts-ignore
		this.icon = Service.BETypes[data.icon] || Service.BETypes.wood;
		this.image = data.featured_media || '';
		this.services = data.related_services || [];
		this.employees = data.related_employees || [];
		this.hasVideo = data.has_video;
		this.hasForm = new OptionalGetter(() => data.custom_content.is_form, false).value;
		this.loadedTimeDate = new Date().valueOf();

		return this;
	}

	// @ts-ignore
	fromStorage(storage: IGenericObject): Service {
		const data = storage.getState().Posts[this.storageId()];

		if (!data) {
			this.loadedTimeDate = 0;

			return this;
		}

		this.id = data.id;
		this.title = data.title;
		this.slug = data.slug;
		this.description = data.description;
		this.content = data.content;
		this.icon = data.icon;
		this.image = data.image;
		this.services = data.services;
		this.employees = data.employees;
		this.hasVideo = data.hasVideo;
		this.hasForm = data.hasForm;
		this.loadedTimeDate = data.loadedTime;

		return this;
	}

	// @ts-ignore
	saveToStorage(storage: IGenericObject): Service {
		storage.dispatch(postsAddItem(this.value()));

		return this;
	}
}

export default Service;
