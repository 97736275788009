import { IGenericObject, IReduxAction } from '../../constants/types';
import { PAGE_ADD_ITEM } from '../ActionTypes';

// eslint-disable-next-line import/prefer-default-export
export const pageAddItem = (data: IGenericObject): IReduxAction => {
	return {
		type: PAGE_ADD_ITEM,
		payload: data,
	};
};
