// @ts-ignore
import v1 from 'uuid/v1';
import { Mutex } from 'async-mutex';
import { IGenericObject, IStorageEntry } from '../constants/types';
import { postsAddItem } from '../redux/actions/Posts';
import OptionalGetter from '../VO/OptionalGetter';

export interface IMediaSizes {
	'684x428': string;
	'490x306': string;
	'408x270': string;
	'448x290': string;
	'1156x560': string;
	'1024x496': string;
	'100x100': string;
	'375x': string;
	'768x': string;
	'1440x': string;
}

class Media implements IStorageEntry {
	static type: string = 'media';

	static mutex = new Mutex();

	static sizes: IMediaSizes = {
		'684x428': '',
		'490x306': '',
		'408x270': '',
		'448x290': '',
		'1156x560': '',
		'1024x496': '',
		'375x': '',
		'768x': '',
		'1440x': '',
		'100x100': '',
	};

	static sizesKeys: IMediaSizes = {
		'684x428': '684x428',
		'490x306': '490x306',
		'408x270': '408x270',
		'448x290': '448x290',
		'1156x560': '1156x560',
		'1024x496': '1024x496',
		'375x': '375x',
		'768x': '768x',
		'1440x': '1440x',
		'100x100': '',
	};

	id: string | number;

	private url: string;

	private sizes: IMediaSizes;

	loadedTimeDate: number = new Date().valueOf();

	constructor(id: string | number | null, url: string = '', sizes: IMediaSizes = Media.sizes) {
		this.id = id === null ? v1() : id;
		this.sizes = sizes;
		this.url = url;
	}

	storageId() {
		return `${Media.type}:${this.id}`;
	}

	value(): {
		storageId: string;
		id: string | number;
		url: string;
		sizes: IMediaSizes;
		loadedTime: number;
	} {
		return {
			storageId: this.storageId(),
			id: this.id,
			url: this.url,
			sizes: this.sizes,
			loadedTime: this.loadedTimeDate,
		};
	}

	loadedTime(): number {
		return this.loadedTimeDate;
	}

	// @ts-ignore
	fromBackend(data: IGenericObject): Media {
		this.id = data.id;
		this.url = data.source_url;
		this.sizes = {
			'684x428': new OptionalGetter(() => data.media_details.sizes['684x428'].source_url, '').trueValue,
			'490x306': new OptionalGetter(() => data.media_details.sizes['490x306'].source_url, '').trueValue,
			'408x270': new OptionalGetter(() => data.media_details.sizes['408x270'].source_url, '').trueValue,
			'448x290': new OptionalGetter(() => data.media_details.sizes['448x290'].source_url, '').trueValue,
			'1156x560': new OptionalGetter(() => data.media_details.sizes['1156x560'].source_url, '').trueValue,
			'1024x496': new OptionalGetter(() => data.media_details.sizes['1024x496'].source_url, '').trueValue,
			'100x100': new OptionalGetter(() => data.media_details.sizes['100x100'].source_url, '').trueValue,
			'375x': new OptionalGetter(() => data.media_details.sizes['375x'].source_url, '').trueValue,
			'768x': new OptionalGetter(() => data.media_details.sizes['768x'].source_url, '').trueValue,
			'1440x': new OptionalGetter(() => data.media_details.sizes['1440x'].source_url, '').trueValue,
		};
		this.loadedTimeDate = new Date().valueOf();

		return this;
	}

	// @ts-ignore
	fromStorage(storage: IGenericObject): Media {
		const data = storage.getState().Posts[this.storageId()];

		if (!data) {
			this.loadedTimeDate = 0;

			return this;
		}

		this.id = data.id;
		this.url = data.url;
		this.sizes = data.sizes;
		this.loadedTimeDate = data.loadedTime;

		return this;
	}

	// @ts-ignore
	saveToStorage(storage: IGenericObject): Media {
		storage.dispatch(postsAddItem(this.value()));

		return this;
	}
}

export default Media;
