import { IGenericObject, IReduxAction } from '../../constants/types';
import { POSTS_ADD_ITEM } from '../ActionTypes';

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state: IGenericObject = {}, action: IReduxAction) => {
	switch (action.type) {
		case POSTS_ADD_ITEM:
			return {
				...state,
				[action.payload.storageId]: action.payload,
			};

		default:
			return state;
	}
};
