import { Global } from '@emotion/react';
import { Route, Routes } from 'react-router-dom';
import React from 'react';
import { Metric } from './constants/fonts';
import Heads from './views/Heads';
import Header from './components/Header';
import Main from './views/Main';
import SiteRouter from './SiteRouter';
import Loading from './views/Loading';
import Footer from './components/Footer';
import NotFoundResolver from './containers/NotFoundResolver';

const MainSlashResolver = React.lazy(() => import('./views/MainSlashResolver'));
const PageResolver = React.lazy(() => import('./containers/PageResolver'));
const NotFound = React.lazy(() => import('./views/NotFound'));
const SingleArticle = React.lazy(() => import('./containers/SingleArticle'));
const SingleService = React.lazy(() => import('./containers/SingleService'));
const ArticlesList = React.lazy(() => import('./containers/ArticlesList'));
const ContactUs = React.lazy(() => import('./containers/ContactUs'));
const ContactUsDepartment = React.lazy(() => import('./containers/ContactUsDepartment'));

const AppLazyRender = (): JSX.Element => {
	return (
		<>
			<Global
				styles={Object.values(Metric).map((font) => ({
					'@font-face': font,
				}))}
			/>
			<Heads />
			<Header />
			<Main>
				<NotFoundResolver>
					<Routes>
						<Route
							path={SiteRouter.home()}
							element={
								<React.Suspense fallback={<Loading />}>
									<MainSlashResolver />
								</React.Suspense>
							}
							// @ts-ignore
							index
						/>
						<Route
							path={SiteRouter.articlesList()}
							element={
								<React.Suspense fallback={<Loading />}>
									<ArticlesList />
								</React.Suspense>
							}
						/>
						<Route
							path={SiteRouter.singleArticle()}
							element={
								<React.Suspense fallback={<Loading />}>
									<SingleArticle />
								</React.Suspense>
							}
						/>
						<Route
							path={SiteRouter.service()}
							element={
								<React.Suspense fallback={<Loading />}>
									<SingleService />
								</React.Suspense>
							}
						/>
						<Route
							path={SiteRouter.contactUsLocations()}
							element={
								<React.Suspense fallback={<Loading />}>
									<ContactUs />
								</React.Suspense>
							}
						/>
						<Route
							path={SiteRouter.contactUsDepartment()}
							element={
								<React.Suspense fallback={<Loading />}>
									<ContactUsDepartment />
								</React.Suspense>
							}
						/>
						<Route
							path={SiteRouter.defaultPage()}
							element={
								<React.Suspense fallback={<Loading />}>
									<PageResolver />
								</React.Suspense>
							}
						/>
						<Route
							path="*"
							element={
								<React.Suspense fallback={<Loading />}>
									<NotFound />
								</React.Suspense>
							}
						/>
					</Routes>
				</NotFoundResolver>
			</Main>
			<Footer />
		</>
	);
};

export default AppLazyRender;
