import styled from '@emotion/styled';
import { defaultPadding, defaultWrapper, narrowWrapper } from '../constants/sizes';

interface IWrapper {
	isFluid?: boolean;
	children: JSX.Element | JSX.Element[];
	isNarrow?: boolean;
	hasNoPadding?: boolean;
	as?: string;
	className?: string;
}

const SWrapper = styled.div<{ isFluid: boolean; isNarrow: boolean; as: string; hasNoPadding: boolean }>`
	padding-left: ${(props) => (props.hasNoPadding ? 0 : defaultPadding)}px;
	padding-right: ${(props) => (props.hasNoPadding ? 0 : defaultPadding)}px;
	max-width: ${(props) => {
		const padding = props.hasNoPadding ? 0 : defaultPadding;

		if (props.isNarrow) {
			return `${narrowWrapper + padding * 2}px`;
		}

		return props.isFluid ? '100%' : `${defaultWrapper + padding * 2}px`;
	}};
	width: 100%;
	margin-left: auto;
	margin-right: auto;
	box-sizing: border-box;
	display: flex;
	flex-flow: column;
`;

const Wrapper = ({
	isFluid = false,
	isNarrow = false,
	hasNoPadding = false,
	children,
	as = 'div',
	className = '',
}: IWrapper): JSX.Element => {
	return (
		// @ts-ignore
		<SWrapper isFluid={isFluid} as={as} className={className} isNarrow={isNarrow} hasNoPadding={hasNoPadding}>
			{children}
		</SWrapper>
	);
};

export default Wrapper;
