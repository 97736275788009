import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
// @ts-ignore
import { CSSTransition } from 'react-transition-group';
// @ts-ignore
import { ReactComponent as Icon } from '../../img/man.svg';

interface IManIcon {
	className?: string;
	isParentActive?: boolean | null;
}

const slideInMaterial = keyframes`
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(2px);
  }
`;

const slideOutMaterial = keyframes`
  0% {
    transform: translateX(2px);
  }

  100% {
    transform: translateX(0px);
  }
`;

const slideInHead = keyframes`
  0% {
    transform: translate(0px, 0px);
  }

  100% {
    transform: translate(-3px, 4px);
  }
`;

const slideOutHead = keyframes`
  0% {
    transform: translate(-3px, 4px);
  }

  100% {
    transform: translate(0px, 0px);
  }
`;

const slideInLowerBody = keyframes`
  0% {
    transform: translateX(0px);
  }

  100% {
    transform: translateX(-2px);
  }
`;

const slideOutLowerBody = keyframes`
  0% {
    transform: translateX(-2px);
  }

  100% {
    transform: translateX(0px);
  }
`;

const slideInTorso = keyframes`
  0% {
    transform: translate(0px, 0px) rotate(0deg) scaleY(1);
    transform-origin: bottom center;
  }

  100% {
    transform: translate(-4px, -18px) rotate(5deg) scaleY(0.6);
    transform-origin: bottom center;
  }
`;

const slideOutTorso = keyframes`
  0% {
    transform: translate(-4px, -18px) rotate(5deg) scaleY(0.6);
    transform-origin: bottom center;
  }

  100% {
    transform: translate(0px, 0px) rotate(0deg) scaleY(1);
    transform-origin: bottom center;
  }
`;

const slideInLeftHand = keyframes`
  0% {
    transform: translate(0px, 0px);
  }

  100% {
    transform: translate(-1px, 1px);
  }
`;

const slideOutLeftHand = keyframes`
  0% {
    transform: translate(-1px, 1px);
  }

  100% {
    transform: translate(0px, 0px);
  }
`;

const slideInLeftShoulder = keyframes`
  0% {
    transform: translate(0px, 0px) rotate(0deg);
    transform-origin: right center;
  }

  100% {
    transform: translate(2px, -6px) rotate(-26deg);
    transform-origin: right center;
  }
`;

const slideOutLeftShoulder = keyframes`
  0% {
    transform: translate(2px, -6px) rotate(-26deg);
    transform-origin: right center;
  }

  100% {
    transform: translate(0px, 0px) rotate(0deg);
    transform-origin: right center;
  }
`;

const slideInRightShoulder = keyframes`
  0% {
    transform: translate(0px, 0) rotate(0deg) scaleY(1);
    transform-origin: top left;
  }

  100% {
    transform: translate(0px, 9px) rotate(4deg) scaleY(0.7);
    transform-origin: top left;
  }
`;

const slideOutRightShoulder = keyframes`
  0% {
    transform: translate(0px, 9px) rotate(4deg) scaleY(0.7);
    transform-origin: top left;
  }

  100% {
    transform: translate(0px, 0) rotate(0deg) scaleY(1);
    transform-origin: top left;
  }
`;

const slideInRightArm = keyframes`
  0% {
    transform-origin: bottom center;
    transform: translate(0px, 0px) rotate(0deg) scaleY(1);
  }

  100% {
    transform-origin: bottom center;
    transform: translate(16px, -5px) rotate(-31deg) scaleY(0.7);
  }
`;

const slideOutRightArm = keyframes`
  0% {
    transform-origin: bottom center;
    transform: translate(16px, -5px) rotate(-31deg) scaleY(0.7);
  }

  100% {
    transform-origin: bottom center;
    transform: translate(0px, 0px) rotate(0deg) scaleY(1);
  }
`;

const SIcon = styled(Icon)<{ isActive: boolean }>`
	display: flex;

	&.man-svg-node-enter-done,
	&.man-svg-node-enter {
		.man_svg__material-circle,
		.man_svg__stick,
		.man_svg__stick-wheel,
		.man_svg__material-line-top,
		.man_svg__material-line-bottom {
			animation-duration: 0.3s;
			animation-name: ${slideInMaterial};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}

		.man_svg__head {
			animation-duration: 0.3s;
			animation-name: ${slideInHead};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}

		.man_svg__torso {
			animation-duration: 0.3s;
			animation-name: ${slideInTorso};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}

		.man_svg__arm-left {
			animation-duration: 0.3s;
			animation-name: ${slideInLeftHand};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}

		.man_svg__arm-right {
			animation-duration: 0.45s;
			animation-name: ${slideInRightArm};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}

		.man_svg__sholder-left {
			animation-duration: 0.3s;
			animation-name: ${slideInLeftShoulder};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}

		.man_svg__sholder-right {
			animation-duration: 0.3s;
			animation-name: ${slideInRightShoulder};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}

		.man_svg__lower-leg-right,
		.man_svg__upper-leg-right,
		.man_svg__upper-leg-left,
		.man_svg__lower-leg-left {
			animation-duration: 0.3s;
			animation-name: ${slideInLowerBody};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}
	}

	&.man-svg-node-exit-done,
	&.man-svg-node-exit {
		.man_svg__material-circle,
		.man_svg__stick,
		.man_svg__stick-wheel,
		.man_svg__material-line-top,
		.man_svg__material-line-bottom {
			animation-duration: 0.3s;
			animation-name: ${slideOutMaterial};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}

		.man_svg__head {
			animation-duration: 0.3s;
			animation-name: ${slideOutHead};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}

		.man_svg__torso {
			animation-duration: 0.3s;
			animation-name: ${slideOutTorso};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}

		.man_svg__arm-left {
			animation-duration: 0.3s;
			animation-name: ${slideOutLeftHand};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}

		.man_svg__arm-right {
			animation-duration: 0.2s;
			animation-name: ${slideOutRightArm};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}

		.man_svg__sholder-left {
			animation-duration: 0.3s;
			animation-name: ${slideOutLeftShoulder};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}

		.man_svg__sholder-right {
			animation-duration: 0.3s;
			animation-name: ${slideOutRightShoulder};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}

		.man_svg__lower-leg-right,
		.man_svg__upper-leg-right,
		.man_svg__upper-leg-left,
		.man_svg__lower-leg-left {
			animation-duration: 0.3s;
			animation-name: ${slideOutLowerBody};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}
	}
`;

const ManIcon = ({ className = '', isParentActive = null }: IManIcon): JSX.Element => {
	const isActive = isParentActive === null ? false : isParentActive;

	return (
		<CSSTransition classNames="man-svg-node" in={isActive} timeout={300}>
			<SIcon className={className} isActive={isActive} />
		</CSSTransition>
	);
};

export default ManIcon;
