import axios, { AxiosInstance } from 'axios';
import OptionalGetter from '../VO/OptionalGetter';

// @ts-ignore
export const wpNonce = new OptionalGetter(() => window.themeSettings.nonce, '').trueValue;

export const defaultClient = axios.create({
	baseURL: '/wp-json/wp/v2',
});

export const resolveClient = (): AxiosInstance => {
	return defaultClient;
};
