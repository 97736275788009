import React from 'react';
import NotFound from '../views/NotFound';
import StoragePage from '../Storage/Page';
import HttpPage from '../VO/Http/Page';
import { resolveClient } from '../constants/httpClients';
import ResourceCache from '../Entities/ResourceCache';
import withRouter, { IRouterPropTypes } from '../views/withRouter';
import LoadingComponent from '../VO/LoadingComponent';

interface INotFoundResolverProps {
	children: any;
	router: IRouterPropTypes;
}

interface INotFoundResolverState {
	is404: boolean;
}

class NotFoundResolver extends React.Component<INotFoundResolverProps, INotFoundResolverState> {
	state = {
		is404: false,
	};

	resourcesInstance = new ResourceCache();

	loadingComponent = new LoadingComponent(this);

	loadResources = async () => {
		this.loadingComponent.startLoading([LoadingComponent.defaultKeys.mainContent]);

		try {
			const { data } = await this.resourcesInstance.requestItem(
				// @ts-ignore
				new StoragePage(window.location.href),
				new HttpPage(resolveClient()),
				StoragePage.mutex
			);

			this.setState({
				is404: data.template === 'page-404',
			});
			// eslint-disable-next-line no-empty
		} catch (e) {}

		this.loadingComponent.finishLoading([LoadingComponent.defaultKeys.mainContent]);
	};

	componentDidMount() {
		this.loadResources();
	}

	componentDidUpdate(prevProps: INotFoundResolverProps) {
		if (
			prevProps.router.location.pathname !== this.props.router.location.pathname ||
			prevProps.router.location.search !== this.props.router.location.search
		) {
			this.loadResources();
		}
	}

	render(): JSX.Element {
		return this.loadingComponent.component(
			LoadingComponent.defaultKeys.mainContent,
			// eslint-disable-next-line react/jsx-no-useless-fragment
			<>{this.state.is404 ? <NotFound /> : this.props.children}</>
		);
	}
}

export default withRouter(NotFoundResolver);
