import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

interface IScrollToTop {
	children: JSX.Element | JSX.Element[];
}

const ScrollToTop = ({ children }: IScrollToTop): JSX.Element => {
	const location = useLocation();
	const [lastLocation, setLastLocation] = useState(location.pathname);

	useEffect(() => {
		if (location.pathname === lastLocation) {
			return;
		}

		setLastLocation(location.pathname);
		window.scrollTo(0, 0);
		// eslint-disable-next-line
  }, [location]);

	// eslint-disable-next-line react/jsx-no-useless-fragment
	return <>{children}</>;
};

export default ScrollToTop;
