import { IGenericObject, IReduxAction } from '../../constants/types';
import { POSTS_ADD_ITEM } from '../ActionTypes';

// eslint-disable-next-line import/prefer-default-export
export const postsAddItem = (data: IGenericObject): IReduxAction => {
	return {
		type: POSTS_ADD_ITEM,
		payload: data,
	};
};
