import React from 'react';
import styled from '@emotion/styled';
import { PrimaryColors } from '../constants/colors';

interface IBurgerButton extends React.HTMLProps<HTMLButtonElement> {
	type?: 'submit' | 'reset' | 'button' | undefined;
	isActive?: boolean;
}

const SButton = styled.button`
	display: flex;
	flex-flow: column;
	justify-content: space-between;
	width: 60px;
	height: 60px;
	padding: 22px 20px;
	border: none;
	margin: 0;
	cursor: pointer;
	background: none;
	position: relative;

	&:focus,
	&:active {
		outline: none;
	}
`;

const SContent = styled.span`
	position: absolute;
	font-size: 0;
`;

const STopBar = styled.span<{ isActive: boolean }>`
	width: 20px;
	height: 2px;
	border-radius: 1px;
	background: ${PrimaryColors.red};
	transition: 300ms all ease;
	transform-origin: top right;
	transform: rotate(${(props) => (props.isActive ? '-45deg' : '0deg')})
		scaleX(${(props) => (props.isActive ? 1.03 : 1)});
`;

const SMiddleBar = styled.span<{ isActive: boolean }>`
	width: 20px;
	height: 2px;
	border-radius: 1px;
	background: ${PrimaryColors.red};
	transition: 200ms all ease;
	transform-origin: right center;
	transform: scaleX(${(props) => (props.isActive ? 0 : 1)});
`;

const SBottomBar = styled.span<{ isActive: boolean }>`
	width: 20px;
	height: 2px;
	border-radius: 1px;
	background: ${PrimaryColors.red};
	transition: 300ms all ease;
	transform-origin: bottom right;
	transform: rotate(${(props) => (props.isActive ? '45deg' : '0deg')}) scaleX(${(props) => (props.isActive ? 1.03 : 1)});
`;

const BurgerButton = ({ isActive = false, ...props }: IBurgerButton): JSX.Element => {
	return (
		<SButton {...props} as="button">
			<SContent>menu</SContent>
			<STopBar isActive={isActive} />
			<SMiddleBar isActive={isActive} />
			<SBottomBar isActive={isActive} />
		</SButton>
	);
};

export default BurgerButton;
