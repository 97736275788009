// @ts-ignore
import v1 from 'uuid/v1';
import { Mutex } from 'async-mutex';
import { IGenericObject, ISinglePostPageData, IStorageEntry } from '../constants/types';
import { pageAddItem } from '../redux/actions/Page';
import OptionalGetter from '../VO/OptionalGetter';
import DefaultFPTopSlide from '../generics/DefaultFPTopSlide';
import DefaultLink from '../generics/DefaultLink';
import LinkHelpers from '../VO/LinkHelpers';
import HeadElement, { IHeadElementValue } from '../generics/HeadElement';
import SearchResult from '../generics/SearchResult';
import HTMLEntities from '../VO/HTMLEntities';

class Page implements IStorageEntry {
	static type: string = '';

	static mutex = new Mutex();

	static singlePostResolver(data: IGenericObject): ISinglePostPageData {
		const id = new OptionalGetter(() => new OptionalGetter(() => data.post_id, data.id).trueValue, '').value;
		const template = new OptionalGetter(() => data.template, '').value;

		return { id, template };
	}

	static singleDepartmentResolver(data: IGenericObject): ISinglePostPageData {
		const id = new OptionalGetter(() => data.id, '').value;
		const template = new OptionalGetter(() => data.template, '').value;
		const [employees, employeesOrder] = new OptionalGetter(() => {
			const list: IGenericObject = {};
			const order: string[] | number[] = [];

			data.employees.forEach(([position, people]: [number | string, number[] | string[]]) => {
				list[position] = people;

				// @ts-ignore
				order.push(position);
			});

			return [list, order];
		}, [{}, []]).trueValue;

		return { id, template, employees, employeesOrder };
	}

	static articlesListResolver(data: IGenericObject): ISinglePostPageData {
		const id = new OptionalGetter(() => data.post_id, '').value;
		const template = new OptionalGetter(() => data.template, '').value;
		const postIds = new OptionalGetter(() => {
			return data.latest_posts.items;
		}, []).trueValue;
		const totalPosts = new OptionalGetter(() => {
			return data.latest_posts.headers['X-WP-Total'];
		}, 0).trueValue;

		return { id, template, postIds, totalPosts };
	}

	static fpResolver(data: IGenericObject): ISinglePostPageData {
		const id = new OptionalGetter(() => data.post_id, '').value;
		const template = new OptionalGetter(() => data.template, '').value;

		const slides = new OptionalGetter(() => {
			return data.hero.map((_item: IGenericObject) => {
				const item: IGenericObject = {
					video: [],
					image: {},
					..._item,
				};
				const isCustomSelection = item.type === 'custom';

				if (!isCustomSelection) {
					return new DefaultFPTopSlide('', '', null, null, null, null, item.post_id, null, false).value();
				}

				const isVideo = item.media_type === 'video';

				return new DefaultFPTopSlide(
					item.title,
					item.description,
					new OptionalGetter(
						() =>
							item.button_block.url
								? new DefaultLink(item.button_block.label, LinkHelpers.toRelative(item.button_block.url))
								: null,
						null
					).value,
					null,
					null,
					new OptionalGetter(
						() =>
							item.button_underline.url
								? new DefaultLink(item.button_underline.label, LinkHelpers.toRelative(item.button_underline.url))
								: null,
						null
					).value,
					0,
					isVideo ? item.video[0] : item.image.id,
					isVideo
				).value();
			});
		}, []).trueValue;

		const departments = new OptionalGetter(() => {
			return data.show_department_block ? data.departments : [];
		}, []).trueValue;

		const articles = new OptionalGetter(() => {
			return data.show_news_block ? data.related_news.posts : [];
		}, []).trueValue;

		const services = new OptionalGetter(() => {
			return data.services;
		}, []).trueValue;

		const testimonials = new OptionalGetter(() => {
			return data.show_quote_block ? data.testimonials : [];
		}, []).trueValue;

		return { id, template, slides, departments, articles, services, testimonials };
	}

	static searchResolver(data: IGenericObject): ISinglePostPageData {
		const id = new OptionalGetter(() => data.post_id, '').value;
		const template = new OptionalGetter(() => data.template, 'search').trueValue;
		const posts = new OptionalGetter(() => {
			return Object.values(data)
				.map((item: IGenericObject) => {
					try {
						return new SearchResult(
							new DefaultLink(new HTMLEntities(item.title).decoded, LinkHelpers.toRelative(item.url), item.id),
							new HTMLEntities(item.excerpt).decoded
						);
					} catch (e) {
						return null;
					}
				})
				.filter((item) => !!item);
		}, []).trueValue;

		return { id, template, posts };
	}

	static templatesDict = {
		service: Page.singlePostResolver,
		department: Page.singleDepartmentResolver,
		post: Page.singlePostResolver,
		blog: Page.articlesListResolver,
		frontpage: Page.fpResolver,
		'page/default': Page.singlePostResolver,
		'page-404': Page.singlePostResolver,
		search: Page.searchResolver,
		default: (data: any) => data,
	};

	id: string | number;

	private data: IGenericObject;

	private head: HeadElement[];

	loadedTimeDate: number = new Date().valueOf();

	constructor(id: string | number | null, data: IGenericObject = {}, head: HeadElement[] = []) {
		this.id = id || v1();
		this.data = data;
		this.head = head;
	}

	storageId() {
		return `${Page.type}:${this.id}`;
	}

	value(): {
		storageId: string;
		id: string | number;
		data: IGenericObject;
		head: IHeadElementValue[];
		loadedTime: number;
	} {
		return {
			storageId: this.storageId(),
			id: this.id,
			data: this.data,
			head: this.head.map((item) => item.toComponent()),
			loadedTime: this.loadedTimeDate,
		};
	}

	loadedTime(): number {
		return this.loadedTimeDate;
	}

	// @ts-ignore
	fromBackend(data: IGenericObject, explicitKey?: string): Page {
		this.id = data.id;
		this.data = new OptionalGetter(
			// @ts-ignore
			() => Page.templatesDict[explicitKey || data.data.template](data.data || data),
			Page.templatesDict.default(data)
		).value;

		this.head = new OptionalGetter(
			// @ts-ignore
			() => Object.keys(data.head).map((key) => new HeadElement(key, data.head[key])),
			[]
		).value;
		this.loadedTimeDate = new Date().valueOf();

		return this;
	}

	// @ts-ignore
	fromStorage(storage: IGenericObject): Page {
		const data = storage.getState().Page[this.storageId()];

		if (!data) {
			this.loadedTimeDate = 0;

			return this;
		}

		this.id = data.id;
		this.data = data.data;
		this.loadedTimeDate = data.loadedTime;

		return this;
	}

	// @ts-ignore
	saveToStorage(storage: IGenericObject): Page {
		storage.dispatch(pageAddItem(this.value()));

		return this;
	}
}

export default Page;
