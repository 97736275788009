import { ILink, ISearchResult } from '../constants/types';

class SearchResult implements ISearchResult {
	description: string;

	link: ILink;

	constructor(link: ILink, description: string) {
		this.link = link;
		this.description = description;
	}

	get value(): {
		description: string;
		link: { url: string; title: string; id: string };
	} {
		return {
			description: this.description,
			link: this.link.value(),
		};
	}
}

export default SearchResult;
