import { useLocation, useNavigate, useParams } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Location } from 'history';
// eslint-disable-next-line import/no-extraneous-dependencies
import { NavigateFunction } from 'react-router/lib/hooks';
import { IGenericObject } from '../constants/types';

export interface IRouterPropTypes {
	location: Location;
	navigate: NavigateFunction;
	params: IGenericObject;
}

export default function withRouter(Component: any) {
	return (props: any) => {
		const location = useLocation();
		const navigate = useNavigate();
		const params = useParams();

		// @ts-ignore
		return <Component {...props} router={{ location, navigate, params }} />;
	};
}
