import { AxiosInstance } from 'axios';
import DropsIcon from '../views/DropsIcon';
import WoodIcon from '../views/WoodIcon';
import ManIcon from '../views/ManIcon';

export interface ILink {
	value(): { url: string; title: string; id: string };
}

export const placeholder: string = 'placeholder';

export interface IImage {
	url: string;
	srcSet: string;
	sizes: string;
}

export enum EPostGridTypes {
	default = 'default',
	construction = 'construction',
	square = 'square',
	drops = 'drops',
}

export const EPostGridIcons = {
	[EPostGridTypes.drops]: DropsIcon,
	[EPostGridTypes.square]: WoodIcon,
	[EPostGridTypes.construction]: ManIcon,
	[EPostGridTypes.default]: DropsIcon,
};

export interface IPostGridComponent {
	image: IImage;
	link: ILink;
	title: string;
	id: string | number;
	excerpt: string;
	type: EPostGridTypes;
	hasVideo: boolean;
}

export interface IContactPerson {
	image: IImage;
	name: string;
	position: string;
	id: string | number;
	phone: ILink | null;
	email: ILink | null;
}

export interface IRelatedService {
	link: ILink;
	title: string;
	id: string | number;
	excerpt: string;
	type: EPostGridTypes;
}

export interface IDepartment {
	description: string;
	link: ILink;
}

export interface IDepartmentBlock {
	title: string;
	persons: IContactPerson[];
}

export interface ISearchResult {
	description: string;
	link: ILink;
}

export interface IFPTopSlide {
	image: IImage;
	category: ILink | null;
	title: string;
	excerpt: string;
	link: ILink | null;
	buttonLink: ILink | null;
	id: string | number;
	imageId: string | number | null;
	isVideo: boolean;
	value(): object;
}

export interface IFPBottomSlide {
	id: string | number;
	title: string;
	excerpt: string;
	image: IImage | null;
}

export interface IGenericObject {
	[key: string]: any;
}

export interface IStorageEntry {
	[key: string]: any;
	constructor(): IStorageEntry;
	id: string | number;
	loadedTimeDate: number;
	storageId(): string;
	value(): object;
	loadedTime(): number;
	fromBackend(data: IGenericObject, explicitKey?: string): IStorageEntry;
	fromStorage(storage: IGenericObject): IStorageEntry;
	saveToStorage(storage: IGenericObject): IStorageEntry;
}

export interface IHttpEntry {
	client: AxiosInstance;
	getItem(id: string | number): Promise<{ [key: string]: any; id: string | number }>;
	getItems(ids: string[] | number[]): Promise<{ [key: string]: any; id: string | number }[]>;
}

export interface IHttpCollection {
	loadList(
		page: number,
		amount: number
	): { url: string; request(): Promise<{ [key: string]: any; id: string | number }[]> };
}

export interface IHttpEntryLists {
	getItemsList(): Promise<{ [key: string]: any; id: string | number }[]>;
}

export interface IReduxAction {
	type: string;
	payload: IGenericObject;
}

export interface IMapLocation {
	lat: number;
	lng: number;
}

export interface ISinglePostPageData {
	[key: string]: any;
	id: string | number;
	template: string;
}
