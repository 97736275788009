import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
// @ts-ignore
import { CSSTransition } from 'react-transition-group';
// @ts-ignore
import { ReactComponent as Icon } from '../../img/wood.svg';

interface IWoodIcon {
	className?: string;
	isParentActive?: boolean | null;
}

const slideInTopWood = keyframes`
  0% {
    transform: translateY(0px);
  }

  70% {
    transform: translateY(-3px);
  }

  100% {
    transform: translateY(-2px);
  }
`;

const slideOutTopWood = keyframes`
  0% {
    transform: translateY(-2px);
  }

  70% {
    transform: translateY(1px);
  }

  100% {
    transform: translateY(0px);
  }
`;

const slideInBottomWood = keyframes`
  0% {
    transform: translateY(0px);
  }

  70% {
    transform: translateY(3px);
  }

  100% {
    transform: translateY(2px);
  }
`;

const slideOutBottomWood = keyframes`
  0% {
    transform: translateY(2px);
  }

  70% {
    transform: translateY(-1px);
  }

  100% {
    transform: translateY(0px);
  }
`;

const SIcon = styled(Icon)<{ isActive: boolean }>`
	display: flex;

	&.wood-svg-node-enter-done,
	&.wood-svg-node-enter {
		.wood_svg__top {
			animation-duration: 0.3s;
			animation-name: ${slideInTopWood};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}

		.wood_svg__bottom {
			animation-duration: 0.3s;
			animation-name: ${slideInBottomWood};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}
	}

	&.wood-svg-node-exit-done,
	&.wood-svg-node-exit {
		.wood_svg__top {
			animation-duration: 0.3s;
			animation-name: ${slideOutTopWood};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}

		.wood_svg__bottom {
			animation-duration: 0.3s;
			animation-name: ${slideOutBottomWood};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}
	}
`;

const WoodIcon = ({ className = '', isParentActive = null }: IWoodIcon): JSX.Element => {
	const isActive = isParentActive === null ? false : isParentActive;

	return (
		<CSSTransition classNames="wood-svg-node" in={isActive} timeout={300}>
			<SIcon className={className} isActive={isActive} />
		</CSSTransition>
	);
};

export default WoodIcon;
