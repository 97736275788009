import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import App from './App';
import ScrollToTop from './views/ScrollToTop';
import store from './redux/store';

export default () => {
	const container = document.getElementById('app') || document.createElement('div');

	if (container.hasChildNodes()) {
		return;
	}

	const key = 'st';
	// @ts-ignore
	// eslint-disable-next-line no-underscore-dangle
	const isPrerender = window.__INNOCODE_PRERENDER__;

	if (isPrerender) {
		let style: Element | undefined;

		const observer = new MutationObserver((mutations) => {
			mutations.forEach((mutation) => {
				mutation.addedNodes.forEach((node) => {
					if (node.parentElement && node.parentElement.tagName === 'STYLE' && 'emotion' in node.parentElement.dataset) {
						if (!style) {
							style = document.createElement('style');
							style.id = 'style-app-prerender';
							container.prepend(style);
						}

						style.append(node.cloneNode());
						node.parentElement.remove();
					}
				});
			});
		});

		observer.observe(document.head, {
			childList: true,
			subtree: true,
		});
	}

	const emotionCache = createCache({
		key,
		speedy: !isPrerender,
	});

	createRoot(container!).render(
		<Provider store={store}>
			<BrowserRouter>
				<ScrollToTop>
					<CacheProvider value={emotionCache}>
						<App />
					</CacheProvider>
				</ScrollToTop>
			</BrowserRouter>
		</Provider>
	);
};
