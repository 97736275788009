import OptionalGetter from './VO/OptionalGetter';

class SiteRouter {
	// eslint-disable-next-line class-methods-use-this
	home = () => {
		return '/';
	};

	// eslint-disable-next-line class-methods-use-this
	defaultPage = (slug = ':slug') => {
		return `/${slug}/`;
	};

	// eslint-disable-next-line class-methods-use-this
	articlesList = () => {
		return new OptionalGetter(() => {
			// @ts-ignore
			const articles: string = window.themeSettings.routes.post_archive;

			return articles.startsWith('/') ? articles : `/${articles}`;
		}, '/').value;
	};

	// eslint-disable-next-line class-methods-use-this
	singleArticle = (slug = ':slug') => {
		return `/${['articles', slug].join('/')}/`;
	};

	// eslint-disable-next-line class-methods-use-this
	service = (slug = ':slug') => {
		return `/${['service', slug].join('/')}/`;
	};

	// eslint-disable-next-line class-methods-use-this
	contactUsLocations = () => {
		return new OptionalGetter(() => {
			// @ts-ignore
			const contactUsSlug: string = window.themeSettings.routes.contact;

			return contactUsSlug.startsWith('/') ? `${contactUsSlug}/` : `/${contactUsSlug}/`;
		}, '/').value;
	};

	// eslint-disable-next-line class-methods-use-this
	contactUsDepartment = (slug = ':slug') => {
		return `/${['department', slug].join('/')}/`;
	};
}

export default new SiteRouter();
