import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
// @ts-ignore
import { CSSTransition } from 'react-transition-group';
// @ts-ignore
import { ReactComponent as Icon } from '../../img/drops.svg';

interface IDropsIcon {
	className?: string;
	isParentActive?: boolean | null;
}

const slideInTopDrops = keyframes`
  0% {
    transform: translateY(0px);
  }

  70% {
    transform: translateY(17px);
  }

  100% {
    transform: translateY(15px);
  }
`;

const slideOutTopDrops = keyframes`
  0% {
    transform: translateY(15px);
  }

  70% {
    transform: translateY(-2px);
  }

  100% {
    transform: translateY(0px);
  }
`;

const slideInBottomDrops = keyframes`
  0% {
    transform: translateY(0px);
  }

  70% {
    transform: translateY(-17px);
  }

  100% {
    transform: translateY(-15px);
  }
`;

const slideOutBottomDrops = keyframes`
  0% {
    transform: translateY(-15px);
  }

  70% {
    transform: translateY(2px);
  }

  100% {
    transform: translateY(0px);
  }
`;

const SIcon = styled(Icon)<{ isActive: boolean }>`
	display: flex;

	.drops_svg__border-2 {
		opacity: 1;
		transition: 300ms all ease;
	}

	.drops_svg__border-1 {
		opacity: 0;
		transition: 300ms all ease;
	}

	&.drops-svg-node-enter-done,
	&.drops-svg-node-enter {
		.drops_svg__border-1 {
			opacity: 1;
			transition: 150ms all ease;
		}

		.drops_svg__border-2 {
			opacity: 0;
		}

		.drops_svg__drops-top {
			animation-duration: 0.3s;
			animation-name: ${slideInTopDrops};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}

		.drops_svg__drops-bottom {
			animation-duration: 0.3s;
			animation-name: ${slideInBottomDrops};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}
	}

	&.drops-svg-node-exit-done,
	&.drops-svg-node-exit {
		.drops_svg__border-1 {
			opacity: 0;
		}

		.drops_svg__border-2 {
			opacity: 1;
			transition: 150ms all ease;
		}

		.drops_svg__drops-top {
			animation-duration: 0.3s;
			animation-name: ${slideOutTopDrops};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}

		.drops_svg__drops-bottom {
			animation-duration: 0.3s;
			animation-name: ${slideOutBottomDrops};
			animation-direction: normal;
			animation-fill-mode: forwards;
		}
	}
`;

const DropsIcon = ({ className = '', isParentActive = null }: IDropsIcon): JSX.Element => {
	const isActive = isParentActive === null ? false : isParentActive;

	return (
		<CSSTransition classNames="drops-svg-node" in={isActive} timeout={300}>
			<SIcon className={className} isActive={isActive} />
		</CSSTransition>
	);
};

export default DropsIcon;
