import styled from '@emotion/styled';
import UnderlinedLink, { IUnderlinedLink } from './UnderlinedLink';
import { PrimaryColors } from '../constants/colors';

interface IVisibleUnderlinedLink extends IUnderlinedLink {
	color?: string;
	focusColor?: string;
}

const SLink = styled(UnderlinedLink)<{ color: string; focusColor: string }>`
	color: ${(props) => props.color};

	&:after {
		opacity: 1;
	}

	&:hover {
		color: ${(props) => props.color};

		&:after {
			opacity: 0;
		}
	}

	&:focus,
	&:active {
		color: ${(props) => props.focusColor};
	}
`;

const VisibleUnderlinedLink = ({
	color = PrimaryColors.black,
	focusColor = PrimaryColors.red,
	...props
}: IVisibleUnderlinedLink): JSX.Element => {
	return <SLink {...props} color={color} focusColor={focusColor} />;
};

export default VisibleUnderlinedLink;
