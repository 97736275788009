class LinkHelpers {
	static isRelativeLink(url: string, baseOrigin = window.location.origin): boolean {
		if (!url.startsWith('http')) {
			return true;
		}

		try {
			return new URL(url).origin === baseOrigin;
		} catch (e) {
			return url.startsWith(baseOrigin);
		}
	}

	static toRelative(url: string, baseOrigin = window.location.origin): string {
		return LinkHelpers.isRelativeLink(url, baseOrigin) ? new URL(url).pathname : url;
	}
}

export default LinkHelpers;
