import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import debounce from 'lodash/debounce';
import { useCallback, useEffect } from 'react';
import Wrapper from './Wrapper';
// @ts-ignore
import { ReactComponent as Logo } from '../../img/logo.svg';
// @ts-ignore
import { ReactComponent as IsolaLogo } from '../../img/isola_logo.svg';
// @ts-ignore
import { ReactComponent as LocationPin } from '../../img/location-pin.svg';
import { IDepartment, ILink } from '../constants/types';
import { PrimaryColors, PrimaryRGBColors } from '../constants/colors';
import { Metric } from '../constants/fonts';
import { defaultGrid } from '../constants/commonStyles';
import VisibleUnderlinedLink from './VisibleUnderlinedLink';
import { MediaQuery } from '../constants/breakpoints';
import LinkHelpers from '../VO/LinkHelpers';
import { footerHeight } from '../constants/sizes';

interface IFooter {
	departments: IDepartment[];
	topTitle: string;
	copyright: string;
	logosDescription: string;
	pageLinks: ILink[];
	defaultPhysicalAddress?: string;
	defaultPostAddress?: string;
	defaultPhone?: ILink | null;
	defaultEmail?: ILink | null;
}

const SWrapperTop = styled(Wrapper)`
	background: ${PrimaryColors.black};
`;

const SInnerWrapperTop = styled(Wrapper)`
	${defaultGrid};
	padding-top: 60px;
	padding-bottom: 13px;

	${new MediaQuery().sm(`
  display: flex;
  flex-flow: column;
  padding-top: 30px;
  padding-bottom: 33px;
  gap: 0;
  `).value};
`;

const STopTitle = styled.h4`
	font-family: ${Metric.semiBold.name};
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-weight: 400;
	font-size: 36px;
	line-height: 1.25;
	color: ${PrimaryColors.white};
	grid-column: 1/6;
	padding-top: 7px;

	${new MediaQuery().sm(`
    font-size: 28px;
    margin-bottom: 18px;
  `).value};
`;

const SAddressWrapper = styled.div`
	display: none;
	flex-flow: column;
	align-items: flex-start;

	${new MediaQuery().sm(`display: flex;`).value};
`;

const SAddressTitle = styled.h5`
	margin-bottom: 6px;
	font-family: ${Metric.regular.name};
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-weight: 400;
	font-size: 22px;
	line-height: 1.25;
	color: ${PrimaryColors.white};
`;

const SAddressContent = styled.span<{ hasSmallMargin: boolean }>`
	margin-bottom: ${(props) => (props.hasSmallMargin ? 30 : 40)}px;
	font-family: ${Metric.regular.name};
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 1.25;
	color: ${PrimaryColors.white};
`;

const SAddressLink = styled(VisibleUnderlinedLink)`
	margin-bottom: 40px;
	font-family: ${Metric.regular.name};
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 1.25;
	color: ${PrimaryColors.white};
`;

const SLogosDiv = styled.div`
	display: grid;
	grid-template-columns: repeat(96, 1fr);
	padding: 0 13px 0 20px;
	grid-column: 9 / 13;

	${new MediaQuery().sm(`
    padding-left: 0;
    padding-right: 0;
    padding-top: 5px
  `).value}
`;

const SLogo = styled(Logo)`
	grid-column: 1/64;
	align-self: center;
	max-width: 100%;
	transform: translateY(1px);

	${new MediaQuery().sm(`
    grid-column: 1/68;
  `).value}

	.logo_svg__main-title {
		fill: ${PrimaryColors.white};
	}
`;

const SIsolaLogo = styled(IsolaLogo)`
	grid-column: 77/97;
	align-self: center;
	max-width: 100%;

	${new MediaQuery().sm(`
    grid-column: 76/97;
  `).value}
`;

const SLogosDescription = styled.span`
	grid-column: 1 / 97;
	grid-row: 2 / 3;
	color: rgba(${PrimaryRGBColors.white(0.5)});
	font-family: ${Metric.regular.name};
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.25;
	padding-top: 2px;

	${new MediaQuery().sm(`
    padding-top: 9px;
    text-align: center;
    max-width: 260px;
    margin: 0 auto;
    line-height: 1.56;
    font-size: 18px;
    color: ${PrimaryColors.white};
  `).value}
`;

const SInnerWrapperBottom = styled(Wrapper)`
	${defaultGrid};
	margin-top: 0;
	margin-bottom: 0;
	padding-top: 13px;
	padding-bottom: 81px;
	list-style: none;

	${new MediaQuery().sm(`display: none;`).value};
`;

const SDepartment = styled.li`
	display: flex;
	grid-column: span 4;
	align-items: flex-start;
`;

const SDepartmentLink = styled(Link)`
	display: grid;
	grid-template-columns: 20px 1fr;
	padding: 20px;
	text-decoration: none;
	background: rgba(${PrimaryRGBColors.black2(0.2)});
	width: 100%;
	gap: 9px 20px;
	transition: 300ms all ease;
	height: 100%;

	&:focus,
	&:active {
		outline: none;
		box-shadow: 0 5px 24px rgb(5 0 56 / 20%);
	}
`;

const SDepartmentLogo = styled(LocationPin)`
	color: ${PrimaryColors.red};
	width: 20px;
`;

const SDepartmentTitle = styled.h5`
	color: ${PrimaryColors.white};
	font-family: ${Metric.semiBold.name};
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-weight: 400;
	font-size: 22px;
	line-height: 1.25;
	margin-bottom: -1px;
	padding-top: 3px;
`;

const SDepartmentDescription = styled.span`
	color: ${PrimaryColors.white};
	font-family: ${Metric.regular.name};
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 1.25;
	grid-column: 2 / 3;
`;

const SWrapperBottom = styled(Wrapper)`
	background: ${PrimaryColors.black2};
`;

const SWrapperBottomInner = styled(Wrapper)`
	flex-flow: row;
	justify-content: space-between;
	padding-top: 30px;
	padding-bottom: 30px;

	${new MediaQuery().sm(`
    flex-flow: column;
    align-items: center;
    padding-top: 45px;
    padding-bottom: 25px;
  `).value};
`;

const SCopy = styled.span`
	color: rgba(${PrimaryRGBColors.white(0.6)});
	font-family: ${Metric.regular.name};
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 1.25;

	${new MediaQuery().sm(`order: 1;`).value};
`;

const SInfoLinksWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-end;

	${new MediaQuery().sm(`
    flex-flow: row-reverse;
    justify-content: center;
    margin-bottom: 28px;
  `).value};
`;

const SFooterLink = styled(VisibleUnderlinedLink)`
	font-family: ${Metric.regular.name};
	font-size: 16px;
	font-weight: 400;
	margin-left: 30px;
	transform: translateY(-3px);

	${new MediaQuery().sm(`
    margin-left: 20px;
    margin-right: 20px;
  `).value};

	&:after {
		bottom: -6px;
	}
`;

const Footer = ({
	departments,
	topTitle,
	logosDescription,
	pageLinks,
	defaultPhysicalAddress = '',
	copyright = '',
	defaultPostAddress = '',
	defaultPhone = null,
	defaultEmail = null,
}: IFooter): JSX.Element => {
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const resizeHandler = useCallback(
		debounce(() => {
			const element = document.getElementById('primary-footer');

			if (!element) {
				return;
			}

			document.documentElement.style.setProperty(footerHeight, `${element.clientHeight}px`);
		}, 300),
		[]
	);

	useEffect(() => {
		const RO = new ResizeObserver(() => {
			resizeHandler();
		});

		// @ts-ignore
		RO.observe(document.getElementById('primary-footer'));

		return () => {
			RO.disconnect();
		};
	}, [resizeHandler]);
	const defaultPhoneValue = defaultPhone ? defaultPhone.value() : null;
	const defaultEmailValue = defaultEmail ? defaultEmail.value() : null;

	return (
		<footer id="primary-footer">
			<SWrapperTop isFluid as="section">
				<SInnerWrapperTop>
					<STopTitle>{topTitle}</STopTitle>

					<SAddressWrapper>
						{defaultPhysicalAddress && (
							<>
								<SAddressTitle>Besøksadresse:</SAddressTitle>
								<SAddressContent hasSmallMargin={false}>{defaultPhysicalAddress}</SAddressContent>
							</>
						)}

						{defaultPostAddress && (
							<>
								<SAddressTitle>Postadresse:</SAddressTitle>
								<SAddressContent hasSmallMargin>{defaultPostAddress}</SAddressContent>
							</>
						)}

						{defaultPhoneValue && (
							<>
								<SAddressTitle>Tlf:</SAddressTitle>
								<SAddressLink to={defaultPhoneValue.url} color={PrimaryColors.light}>
									{defaultPhoneValue.title}
								</SAddressLink>
							</>
						)}

						{defaultEmailValue && (
							<>
								<SAddressTitle>Firmapost:</SAddressTitle>
								<SAddressLink to={defaultEmailValue.url} color={PrimaryColors.light}>
									{defaultEmailValue.title}
								</SAddressLink>
							</>
						)}
					</SAddressWrapper>

					<SLogosDiv>
						<SLogo />
						<SIsolaLogo />
						<SLogosDescription>{logosDescription}</SLogosDescription>
					</SLogosDiv>
				</SInnerWrapperTop>
				{departments.length ? (
					<SInnerWrapperBottom as="ul">
						{departments.map((department) => {
							const { url, title, id } = department.link.value();

							return (
								<SDepartment key={id}>
									<SDepartmentLink to={url}>
										<SDepartmentLogo />
										<SDepartmentTitle>{title}</SDepartmentTitle>
										<SDepartmentDescription>{department.description}</SDepartmentDescription>
									</SDepartmentLink>
								</SDepartment>
							);
						})}
					</SInnerWrapperBottom>
				) : (
					// eslint-disable-next-line react/jsx-no-useless-fragment
					<></>
				)}
			</SWrapperTop>
			<SWrapperBottom isFluid as="section">
				<SWrapperBottomInner>
					<SCopy>{copyright}</SCopy>

					<SInfoLinksWrapper>
						{pageLinks.slice(0, 2).map((link) => {
							const linkValue = link.value();

							return (
								<SFooterLink
									to={LinkHelpers.toRelative(linkValue.url)}
									color={PrimaryColors.white}
									focusColor={PrimaryColors.red}
									key={linkValue.id}
								>
									{linkValue.title}
								</SFooterLink>
							);
						})}
					</SInfoLinksWrapper>
				</SWrapperBottomInner>
			</SWrapperBottom>
		</footer>
	);
};

export default Footer;
