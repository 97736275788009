// @ts-ignore
import v1 from 'uuid/v1';
import { IFPTopSlide, IGenericObject, IImage, ILink, placeholder } from '../constants/types';
import Image from './Image';

class DefaultFPTopSlide implements IFPTopSlide {
	buttonLink: ILink | null;

	category: ILink | null;

	excerpt: string;

	image: IImage;

	imageId: string | number | null;

	isVideo: boolean;

	link: ILink | null;

	title: string;

	id: string | number;

	constructor(
		title: string,
		excerpt: string,
		buttonLink?: ILink | null,
		category?: ILink | null,
		image?: IImage | null,
		link?: ILink | null,
		id?: string | number | null,
		imageId?: string | number | null,
		isVideo?: boolean | null
	) {
		this.title = title;
		this.excerpt = excerpt;
		this.id = id === null ? v1() : id;
		this.image = image || new Image(placeholder);
		this.link = link || null;
		this.buttonLink = buttonLink || null;
		this.category = category || null;
		this.imageId = imageId || null;
		// @ts-ignore
		this.isVideo = isVideo === null ? false : isVideo;
	}

	value(): {
		buttonLink: IGenericObject | null;
		category: IGenericObject | null;
		excerpt: string;
		image: IImage | null;
		imageId: string | number | null;
		isVideo: boolean;
		link: IGenericObject | null;
		title: string;
		id: string | number;
	} {
		return {
			buttonLink: this.buttonLink ? this.buttonLink.value() : null,
			category: this.category ? this.category.value() : null,
			excerpt: this.excerpt,
			image: this.image,
			imageId: this.imageId,
			isVideo: this.isVideo,
			link: this.link ? this.link.value() : null,
			title: this.title,
			id: this.id,
		};
	}
}

export default DefaultFPTopSlide;
