import { useState, useEffect } from 'react';
import debounce from 'lodash/debounce';

interface Size {
	width: number;
	height: number;
}

const useWindowSize = (): Size => {
	const [windowSize, setWindowSize] = useState<Size>({
		width: 0,
		height: 0,
	});

	useEffect(() => {
		const handleResize = debounce(() => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		}, 100);
		window.addEventListener('resize', handleResize);
		handleResize();
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowSize;
};

export default useWindowSize;
