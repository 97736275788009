import React from 'react';
import { IGenericObject } from '../constants/types';
import Loading from '../views/Loading';

class LoadingComponent {
	static defaultKeys = {
		mainContent: 'mainContent',
	};

	private thisRef: any;

	private loadingKeys: IGenericObject;

	private stateKey: string;

	constructor(
		thisRef: any,
		loadingKeys: IGenericObject = LoadingComponent.defaultKeys,
		stateKey: string = 'loadingKeys'
	) {
		this.thisRef = thisRef;
		this.loadingKeys = loadingKeys;
		this.stateKey = stateKey;

		this.thisRef.state[this.stateKey] = {};

		Object.keys(this.loadingKeys).forEach((key) => {
			this.thisRef.state[this.stateKey][key] = false;
		});
	}

	isLoading = (key: string): boolean => {
		return this.thisRef.state[this.stateKey][key];
	};

	startLoading = (keys: string[]): Promise<{}> => {
		return this.changeLoadingKeys(keys, true);
	};

	finishLoading = (keys: string[]): Promise<{}> => {
		return this.changeLoadingKeys(keys, false);
	};

	component = (key: string, children: any) => {
		return this.isLoading(key) ? React.createElement(Loading) : children;
	};

	private changeLoadingKeys = (keys: string[], nextValue: boolean): Promise<{}> => {
		const changeKeys: IGenericObject = {};

		keys.forEach((key) => {
			changeKeys[key] = nextValue;
		});

		return new Promise((resolve) => {
			this.thisRef.setState(
				(prevState: IGenericObject) => ({
					[this.stateKey]: {
						...prevState[this.stateKey],
						...changeKeys,
					},
				}),
				resolve
			);
		});
	};
}

export default LoadingComponent;
