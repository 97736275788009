// @ts-ignore
import v1 from 'uuid/v1';
import { Mutex } from 'async-mutex';
import { IGenericObject, IStorageEntry } from '../constants/types';
import { postsAddItem } from '../redux/actions/Posts';
import OptionalGetter from '../VO/OptionalGetter';
import HTMLEntities from '../VO/HTMLEntities';

class Article implements IStorageEntry {
	static type: string = 'article';

	static mutex = new Mutex();

	id: string | number;

	private title: string;

	private slug: string;

	private description: string;

	private content: string;

	private image: string | number;

	private relatedPosts: string[] | number[];

	private hasVideo: boolean;

	private hasForm: boolean;

	private showContactBlock: boolean;

	private privacyHtml: string | null;

	loadedTimeDate: number = new Date().valueOf();

	constructor(
		id: string | number | null,
		title: string = '',
		description: string = '',
		slug: string = '',
		content: string = '',
		image: string | number = '',
		relatedPosts: string[] | number[] = [],
		hasVideo: boolean = false,
		hasForm: boolean = false,
		showContactBlock: boolean = false,
		privacyHtml: string | null = null
	) {
		this.id = id || v1();
		this.title = title;
		this.slug = slug;
		this.content = content;
		this.image = image;
		this.description = description;
		this.relatedPosts = relatedPosts;
		this.hasVideo = hasVideo;
		this.hasForm = hasForm;
		this.showContactBlock = showContactBlock;
		this.privacyHtml = privacyHtml;
	}

	storageId() {
		return `${Article.type}:${this.id}`;
	}

	value(): {
		storageId: string;
		id: string | number;
		title: string;
		slug: string;
		description: string;
		content: string;
		image: string | number;
		relatedPosts: string[] | number[];
		hasVideo: boolean;
		hasForm: boolean;
		showContactBlock: boolean;
		loadedTime: number;
		privacyHtml: string | null;
	} {
		return {
			storageId: this.storageId(),
			id: this.id,
			title: this.title,
			slug: this.slug,
			description: this.description,
			content: this.content,
			image: this.image,
			relatedPosts: this.relatedPosts,
			hasVideo: this.hasVideo,
			hasForm: this.hasForm,
			showContactBlock: this.showContactBlock,
			privacyHtml: this.privacyHtml,
			loadedTime: this.loadedTimeDate,
		};
	}

	loadedTime(): number {
		return this.loadedTimeDate;
	}

	// @ts-ignore
	fromBackend(data: IGenericObject): Article {
		this.id = data.id;
		this.title = new OptionalGetter(() => new HTMLEntities(data.title.rendered).decoded, '').value;
		this.slug = data.slug || '';
		this.description = new OptionalGetter(() => data.excerpt.rendered, '').value;
		this.content = new OptionalGetter(() => data.custom_content.rendered, '').value;
		this.image = data.featured_media || '';
		this.relatedPosts = data.related_posts || [];
		this.hasVideo = data.has_video;
		this.hasForm = new OptionalGetter(() => data.custom_content.is_form, false).value;
		this.showContactBlock = data.show_cta_block;
		this.privacyHtml = data.privacy_html || null;
		this.loadedTimeDate = new Date().valueOf();

		return this;
	}

	// @ts-ignore
	fromStorage(storage: IGenericObject): Article {
		const data = storage.getState().Posts[this.storageId()];

		if (!data) {
			this.loadedTimeDate = 0;

			return this;
		}

		this.id = data.id;
		this.title = data.title;
		this.slug = data.slug;
		this.description = data.description;
		this.content = data.content;
		this.image = data.image;
		this.relatedPosts = data.relatedPosts;
		this.hasVideo = data.hasVideo;
		this.hasForm = data.hasForm;
		this.showContactBlock = data.showContactBlock;
		this.privacyHtml = data.privacyHtml;
		this.loadedTimeDate = data.loadedTime;

		return this;
	}

	// @ts-ignore
	saveToStorage(storage: IGenericObject): Article {
		storage.dispatch(postsAddItem(this.value()));

		return this;
	}
}

export default Article;
