import React from 'react';
import debounce from 'lodash/debounce';
import { heightPoint } from './constants/sizes';
import Service from './Storage/Service';
import Article from './Storage/Article';
import Testimonial from './Storage/Testimonial';
import Department from './Storage/Department';
import Employee from './Storage/Employee';
import Position from './Storage/Position';
import Media from './Storage/Media';
import Page from './Storage/Page';
import storeObject from './redux/store';
import AppLazyRender from './AppLazyRender';

interface AppProps {
	mountedHandler(): void;
}

interface AppState {}

class App extends React.Component<AppProps, AppState> {
	static objectsRef = {
		service: Service,
		post: Article,
		testimonial: Testimonial,
		department: Department,
		employee: Employee,
		position: Position,
		attachment: Media,
	};

	// eslint-disable-next-line react/static-property-placement
	static defaultProps = {
		// eslint-disable-next-line react/default-props-match-prop-types
		mountedHandler: () => {},
	};

	// @ts-ignore
	// eslint-disable-next-line react/sort-comp
	constructor(props) {
		super(props);

		this.loadInitialState();
	}

	// eslint-disable-next-line class-methods-use-this
	unsubscribeToHeight = () => {};

	// eslint-disable-next-line class-methods-use-this
	subscribeToHeight = () => {
		const handler = debounce(() => {
			const ratio = window.innerHeight / 100;
			document.documentElement.style.setProperty(heightPoint, `${ratio}px`);
		}, 50);

		handler();

		window.addEventListener('resize', handler);

		return () => {
			window.removeEventListener('resize', handler);
		};
	};

	/* eslint-disable */
	loadInitialState = () => {
		try {
			[
				// @ts-ignore
				...Object.values(window.__INITIAL_STATE__.posts_by_ids || {}),
				// @ts-ignore
				...Object.values(window.__INITIAL_STATE__.terms_by_ids || {}),
			].forEach((data) => {
				// @ts-ignore
				if (!App.objectsRef[data.type || data.taxonomy]) {
					console.log({
						data,
					});
					return;
				}

				// @ts-ignore
				const InstanceRef = App.objectsRef[data.type || data.taxonomy];
				new InstanceRef().fromBackend(data).saveToStorage(storeObject);
			});
		} catch (e) {
			console.log(e);
		}

		try {
			new Page(window.location.href)
				.fromBackend({
					// @ts-ignore
					data: window.__INITIAL_STATE__.data || window.__INITIAL_STATE__,
					head: {},
					id: window.location.href,
				})
				.saveToStorage(storeObject);
		} catch (e) {
			console.log(e);
		}

		const urlInstance = new URL(window.location.href);
		if (!(urlInstance.pathname === '/' && urlInstance.searchParams.get('s'))) {
			return;
		}

		try {
			const urlString = `/search?page=1&per_page=10&search=${urlInstance.searchParams.get('s')}`;

			new Page(urlString)
				.fromBackend(
					{
						// @ts-ignore
						data: window.__INITIAL_STATE__.posts.reduce((acc, item, idx) => ({ ...acc, [idx]: item }), {}),
						head: {},
						id: urlString,
					},
					'search'
				)
				.saveToStorage(storeObject);
		} catch (e) {
			console.log(e);
		}
	};
	/* eslint-enable */

	componentDidMount() {
		this.unsubscribeToHeight = this.subscribeToHeight();
		setTimeout(() => {
			this.props.mountedHandler();
		}, 2000);
	}

	componentWillUnmount() {
		this.unsubscribeToHeight();
	}

	render() {
		return <AppLazyRender />;
	}
}

export default App;
