import React from 'react';
import FooterView from '../views/Footer';
import Department from '../models/Department';
import DefaultLink from '../generics/DefaultLink';
import SiteRouter from '../SiteRouter';
import { IDepartment, IGenericObject, ILink } from '../constants/types';
import ResourceCache from '../Entities/ResourceCache';
import StorageDepartment from '../Storage/Department';
import HttpDepartment from '../VO/Http/Department';
import { resolveClient } from '../constants/httpClients';
import OptionalGetter from '../VO/OptionalGetter';

interface IFooterProps {}

interface IFooterState {
	departments: IDepartment[];
	pageLinks: ILink[];
	topTitle: string;
	logosDescription: string;
	copyright: string;
	defaultPhysicalAddress: string;
	defaultPostAddress: string;
	defaultPhone: ILink | null;
	defaultEmail: ILink | null;
}

class Footer extends React.Component<IFooterProps, IFooterState> {
	state = {
		departments: [],
		pageLinks: [],
		topTitle: 'Hesselbergtak',
		logosDescription: '',
		copyright: '',
		defaultPhysicalAddress: '',
		defaultPostAddress: '',
		defaultPhone: null,
		defaultEmail: null,
	};

	resourcesInstance = new ResourceCache();

	requestResources = async () => {
		this.setState({
			pageLinks: new OptionalGetter(
				() =>
					// @ts-ignore
					window.themeSettings.footer.menu.items.map(
						(menuItem: IGenericObject) => new DefaultLink(menuItem.title, menuItem.url, menuItem.ID)
					),
				[]
			).value,
			// @ts-ignore
			logosDescription: new OptionalGetter(() => window.themeSettings.footer.disclaimer, '').value,
			// @ts-ignore
			copyright: new OptionalGetter(() => window.themeSettings.footer.copyright, '').value,
			// @ts-ignore
			defaultPhysicalAddress: new OptionalGetter(() => window.themeSettings.footer.physical_address, '').value,
			// @ts-ignore
			defaultPostAddress: new OptionalGetter(() => window.themeSettings.footer.mail_address, '').value,
			defaultPhone: new OptionalGetter(() => {
				// @ts-ignore
				if (!window.themeSettings.footer.phone) {
					throw new Error('Empty phone');
				}

				return new DefaultLink(
					// @ts-ignore
					window.themeSettings.footer.phone,
					// @ts-ignore
					`tel:${window.themeSettings.footer.phone}`.replace(/ /g, '')
				);
			}, null).value,
			defaultEmail: new OptionalGetter(() => {
				// @ts-ignore
				if (!window.themeSettings.footer.email) {
					throw new Error('Empty email');
				}

				// @ts-ignore
				return new DefaultLink(window.themeSettings.footer.email, `mailto:${window.themeSettings.footer.email}`);
			}, null).value,
		});

		// @ts-ignore
		const departments = new OptionalGetter(() => window.themeSettings.footer.departments, []).value;

		const departmentsObject = await this.resourcesInstance.requestItemsObject(
			departments.map((id: string | number) => new StorageDepartment(id)),
			new HttpDepartment(resolveClient()),
			StorageDepartment.mutex
		);

		this.setState({
			departments: departments.reduce(
				(acc: Department[], department: number | string) =>
					departmentsObject[department]
						? [
								...acc,
								new Department(
									departmentsObject[department].address,
									new DefaultLink(
										departmentsObject[department].name,
										SiteRouter.contactUsDepartment(departmentsObject[department].slug)
									)
								),
						  ]
						: acc,
				[]
			),
		});
	};

	componentDidMount() {
		this.requestResources();
	}

	render(): JSX.Element {
		return (
			<FooterView
				departments={this.state.departments}
				topTitle={this.state.topTitle}
				logosDescription={this.state.logosDescription}
				copyright={this.state.copyright}
				pageLinks={this.state.pageLinks}
				defaultPhysicalAddress={this.state.defaultPhysicalAddress}
				defaultPostAddress={this.state.defaultPostAddress}
				defaultPhone={this.state.defaultPhone}
				defaultEmail={this.state.defaultEmail}
			/>
		);
	}
}

export default Footer;
