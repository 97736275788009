import { IImage } from '../constants/types';
import Media, { IMediaSizes } from '../Storage/Media';

class Image implements IImage {
	#sizesArray: { size: string; screenWidth: string | number; source: keyof IMediaSizes }[];

	#sizes: string;

	#srcSet: string;

	#url: string;

	constructor(url: string = '') {
		this.#url = url;
		this.#sizes = '';
		this.#srcSet = '';
		this.#sizesArray = [];
	}

	get url(): string {
		return this.#url;
	}

	get srcSet(): string {
		return this.#srcSet;
	}

	get sizes(): string {
		return this.#sizes;
	}

	setSizes = (sizes: { size: string; screenWidth: string; source: keyof IMediaSizes }[]): Image => {
		this.#sizesArray = sizes;

		return this;
	};

	fromStorage = (data: Media): Image => {
		const value = data.value();

		this.#url = value.url;
		this.#sizes = this.#sizesArray
			.map((size) =>
				[size.screenWidth ? `(max-width: ${size.screenWidth}px)` : '', `${size.size}px`]
					.filter((item) => !!item)
					.join(' ')
			)
			.join(', ');

		this.#srcSet = this.#sizesArray
			.map((size) => (value.sizes[size.source] ? `${value.sizes[size.source]} ${size.size}w` : ''))
			.join(', ');

		return this;
	};
}

export default Image;
