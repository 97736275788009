import { AxiosInstance } from 'axios';
import { IGenericObject, IHttpEntry, IHttpEntryLists } from '../../constants/types';
import { wpNonce } from '../../constants/httpClients';

class Department implements IHttpEntry, IHttpEntryLists {
	client: AxiosInstance;

	static path: string = '/department';

	constructor(client: AxiosInstance) {
		this.client = client;
	}

	// eslint-disable-next-line class-methods-use-this
	get headers(): IGenericObject {
		return {
			'X-WP-Nonce': wpNonce,
		};
	}

	getItem = async (id: string | number): Promise<{ [key: string]: any; id: string | number }> => {
		const { data } = await this.client.get(`${Department.path}/${id}`, {
			headers: this.headers,
		});

		return data;
	};

	getItems = async (ids: string[] | number[]): Promise<{ [key: string]: any; id: string | number }[]> => {
		const { data } = await this.client.get(`${Department.path}`, {
			params: {
				include: ids,
				per_page: ids.length,
			},
			headers: this.headers,
		});

		return data.map((item: object) => ({
			id: 0,
			...item,
		}));
	};

	getItemsList = async (): Promise<{ [p: string]: any; id: string | number }[]> => {
		const { data } = await this.client.get(`${Department.path}`, {
			params: {
				per_page: 100,
			},
		});

		return data.map((item: object) => ({
			id: 0,
			...item,
		}));
	};
}

export default Department;
