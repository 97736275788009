import styled from '@emotion/styled';

interface IMain {
	children: JSX.Element | JSX.Element[];
}

const SMain = styled.main`
	flex: 1 0 auto;
	width: 100%;
	display: flex;
	flex-flow: column;
	//min-height: 100vh;
`;

const Main = ({ children }: IMain): JSX.Element => {
	return <SMain>{children}</SMain>;
};

export default Main;
