import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { PrimaryColors, PrimaryRGBColors } from '../../constants/colors';
import UnstyledButton from '../UnstyledButton';
// @ts-ignore
import { ReactComponent as SearchIcon } from '../../../img/search.svg';
import { Metric } from '../../constants/fonts';
import { MediaQuery } from '../../constants/breakpoints';

interface IInputBox {
	searchText: string;
	onSearch(input: string): void;
	isDisabled: boolean;
	placeholder?: string;
}

const SWrapper = styled.div`
	display: flex;
	flex-flow: row;
	position: relative;
	margin-top: 6px;
	margin-bottom: 22px;

	&:after {
		position: absolute;
		content: '';
		bottom: -22px;
		left: 0;
		right: 0;
		height: 2px;
		background: ${PrimaryColors.black};

		${new MediaQuery().sm(`
      bottom: -17px;
    `).value}
	}
`;

const SLabel = styled.label`
	display: flex;
	width: 100%;
	font-size: 0;
`;

const SInput = styled.input`
	flex-grow: 1;
	border: none;
	background: none;
	font-family: ${Metric.semiBold.name};
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-weight: 400;
	font-size: 54px;
	line-height: 1;
	color: ${PrimaryColors.black};
	padding: 0;
	box-sizing: border-box;
	height: 60px;

	&:focus,
	&:active {
		outline: none;
	}

	&:disabled {
		cursor: not-allowed;
	}

	${new MediaQuery().sm(`
    font-size: 28px;
    min-width: 0;
    height: 50px;
  `).value}
`;

const SDiscard = styled(UnstyledButton)<{ isVisible: boolean }>`
	height: 60px;
	width: 60px;
	opacity: ${(props) => (props.isVisible ? 1 : 0)};
	transition: 300ms all ease;
	justify-content: center;
	margin-right: 10px;
	flex-shrink: 0;

	&:hover {
		span {
			&:after,
			&:before {
				background: rgba(${PrimaryRGBColors.black(0.8)});
			}
		}
	}

	&:disabled {
		cursor: not-allowed;
	}

	${new MediaQuery().sm(`
    height: 50px;
    width: 50px;
  `).value}
`;

const SSpan = styled.span`
	position: relative;
	height: 26px;
	width: 26px;
	display: flex;
	transform: rotate(45deg);

	&:after,
	&:before {
		content: '';
		position: absolute;
		background: ${PrimaryColors.black};
		transition: 300ms all ease;
	}

	&:after {
		top: 0;
		left: 50%;
		height: 100%;
		width: 2px;
		transform: translateX(-50%);
	}

	&:before {
		top: 50%;
		left: 0;
		height: 2px;
		width: 100%;
		transform: translateY(-50%);
	}
`;

const SSearch = styled(UnstyledButton)`
	background: ${PrimaryColors.red};
	height: 60px;
	width: 60px;
	justify-content: center;
	flex-shrink: 0;

	&:hover {
		background: transparent;

		svg {
			color: ${PrimaryColors.red};
		}
	}

	&:disabled {
		background: rgba(${PrimaryRGBColors.red(0.8)});
		cursor: not-allowed;

		&:hover {
			svg {
				color: ${PrimaryColors.white};
			}
		}
	}

	${new MediaQuery().sm(`
    height: 50px;
    width: 50px;
  `).value}
`;

const SIcon = styled(SearchIcon)`
	color: ${PrimaryColors.white};
	height: 20px;
	width: 20px;
	transition: 300ms all ease;
`;

const InputBox = ({ searchText, onSearch, isDisabled, placeholder = 'Hva ser du etter?' }: IInputBox): JSX.Element => {
	const [search, setSearch] = useState(searchText);

	useEffect(() => {
		setSearch(searchText);
	}, [searchText]);

	return (
		<SWrapper>
			<SLabel>
				search
				<SInput
					value={search}
					onChange={(e) => setSearch(e.target.value)}
					placeholder={placeholder}
					disabled={isDisabled}
					onKeyDown={(e) => {
						if (e.key !== 'Enter') {
							return;
						}

						onSearch(search);
					}}
				/>
			</SLabel>
			<SDiscard isVisible={search !== ''} onClick={() => setSearch('')} disabled={isDisabled}>
				<SSpan />
			</SDiscard>
			<SSearch onClick={() => onSearch(search)} disabled={isDisabled}>
				<SIcon />
			</SSearch>
		</SWrapper>
	);
};

export default InputBox;
