import MutexInterface from 'async-mutex/lib/MutexInterface';
import { IHttpCollection, IHttpEntry, IStorageEntry } from '../constants/types';
import CacheTime from './CacheTime';
import storeObject from '../redux/store';

class ResourceCache {
	defaultCacheTime = new CacheTime().addMinutes(15);

	storeObject = storeObject;

	requestItem = async (
		storageInstance: IStorageEntry,
		httpInstance: IHttpEntry,
		mutex: MutexInterface,
		cacheTime: CacheTime = this.defaultCacheTime,
		templateKey?: string
	): Promise<IStorageEntry> => {
		const release = await mutex.acquire();

		const storageRecord = storageInstance.fromStorage(this.storeObject);

		if (cacheTime.isValid(storageRecord.loadedTime())) {
			release();

			return storageRecord;
		}

		const data = await httpInstance.getItem(storageInstance.id);

		storageInstance.fromBackend(data, templateKey).saveToStorage(this.storeObject);

		release();

		return storageInstance;
	};

	// @TODO: fix retuning posts order
	requestItems = async (
		storageInstances: IStorageEntry[],
		httpInstance: IHttpEntry,
		mutex: MutexInterface,
		cacheTime: CacheTime = this.defaultCacheTime
	): Promise<IStorageEntry[]> => {
		const release = await mutex.acquire();

		const instancesObject: { [key: string]: string } = {};
		const responseKeys: { [key: string]: object } = {};
		const responseData: IStorageEntry[] = [];

		storageInstances.forEach((instance) => {
			if (!cacheTime.isValid(instance.fromStorage(this.storeObject).loadedTime())) {
				instancesObject[instance.id] = '';

				return;
			}

			responseData.push(instance);
		});

		const idsToRequest = Object.keys(instancesObject);

		if (!idsToRequest.length) {
			release();

			return responseData;
		}

		const data = await httpInstance.getItems(idsToRequest);

		data.forEach((item) => {
			responseKeys[item.id] = item;
		});

		storageInstances.forEach((instance) => {
			if (!Object.prototype.hasOwnProperty.call(responseKeys, instance.id)) {
				return;
			}

			responseData.push(instance.fromBackend(responseKeys[instance.id]).saveToStorage(this.storeObject));
		});

		release();

		return responseData;
	};

	requestItemsObject = async (
		storageInstances: IStorageEntry[],
		httpInstance: IHttpEntry,
		mutex: MutexInterface,
		cacheTime: CacheTime = this.defaultCacheTime
	): Promise<{ [id: string]: IStorageEntry }> => {
		const items = await this.requestItems(storageInstances, httpInstance, mutex, cacheTime);
		const object: { [id: string]: IStorageEntry } = {};

		items.forEach((item) => {
			object[item.id] = item;
		});

		return object;
	};

	requestItemsList = async (
		StorageInstanceRef: IStorageEntry,
		UrlInstanceRef: IStorageEntry,
		httpInstance: IHttpEntry & IHttpCollection,
		page: number,
		amount: number,
		mutex: MutexInterface,
		cacheTime: CacheTime = this.defaultCacheTime
	) => {
		const release = await mutex.acquire();

		const { url, request } = httpInstance.loadList(page, amount);

		// @ts-ignore
		const urlInstance = new UrlInstanceRef(url).fromStorage(this.storeObject);

		if (cacheTime.isValid(urlInstance.loadedTime())) {
			release();
			return urlInstance;
		}

		const data = await request();

		const dataIds = data.map((dataItem) => ({ id: dataItem.id }));

		urlInstance.fromBackend(dataIds);
		urlInstance.id = url;

		urlInstance.saveToStorage(this.storeObject);

		// @ts-ignore
		data.map((item) => new StorageInstanceRef().fromBackend(item).saveToStorage(this.storeObject));

		release();

		return urlInstance;
	};
}

export default ResourceCache;
