import React from 'react';
import styled from '@emotion/styled';
import { Metric } from '../constants/fonts';
import { PrimaryColors } from '../constants/colors';

interface IUnstyledButton extends React.HTMLProps<HTMLButtonElement> {
	type?: 'submit' | 'reset' | 'button' | undefined;
}

const SButton = styled.button`
	transition: 300ms all ease-in-out;
	font-family: ${Metric.semiBold.name};
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 1.25;
	color: ${PrimaryColors.black};
	display: flex;
	align-items: center;
	padding: 5px 10px;
	border: none;
	background: none;
	cursor: pointer;

	&:focus,
	&:active {
		outline: none;
		color: ${PrimaryColors.red};
	}
`;

const UnstyledButton = ({ ...props }: IUnstyledButton): JSX.Element => {
	return <SButton {...props} as="button" aria-label="button" />;
};

export default UnstyledButton;
