// @ts-ignore
import v1 from 'uuid/v1';
import { Mutex } from 'async-mutex';
import { IGenericObject, IStorageEntry } from '../constants/types';
import { postsAddItem } from '../redux/actions/Posts';
import HTMLEntities from '../VO/HTMLEntities';

class Position implements IStorageEntry {
	static type: string = 'position';

	static mutex = new Mutex();

	id: string | number;

	private name: string;

	private parentId: string | number;

	private description: string;

	loadedTimeDate: number = new Date().valueOf();

	constructor(id: string | number | null, name: string = '', description: string = '', parentId: string | number = 0) {
		this.id = id || v1();
		this.name = name;
		this.parentId = parentId;
		this.description = description;
	}

	storageId() {
		return `${Position.type}:${this.id}`;
	}

	value(): {
		storageId: string;
		id: string | number;
		name: string;
		parentId: string | number;
		description: string;
		loadedTime: number;
	} {
		return {
			storageId: this.storageId(),
			id: this.id,
			name: this.name,
			parentId: this.parentId,
			description: this.description,
			loadedTime: this.loadedTimeDate,
		};
	}

	loadedTime(): number {
		return this.loadedTimeDate;
	}

	// @ts-ignore
	fromBackend(data: IGenericObject): Position {
		this.id = data.id;
		this.name = new HTMLEntities(data.name).decoded;
		this.parentId = data.parent;
		this.description = data.description;
		this.loadedTimeDate = new Date().valueOf();

		return this;
	}

	// @ts-ignore
	fromStorage(storage: IGenericObject): Position {
		const data = storage.getState().Posts[this.storageId()];

		if (!data) {
			this.loadedTimeDate = 0;

			return this;
		}

		this.id = data.id;
		this.name = data.name;
		this.parentId = data.parentId;
		this.description = data.description;
		this.loadedTimeDate = data.loadedTime;

		return this;
	}

	// @ts-ignore
	saveToStorage(storage: IGenericObject): Position {
		storage.dispatch(postsAddItem(this.value()));

		return this;
	}
}

export default Position;
