import * as React from 'react';
import { NavLink as BaseNavLink, NavLinkProps } from 'react-router-dom';
import styled from '@emotion/styled';
import { useEffect } from 'react';
import { Metric } from '../constants/fonts';
import { PrimaryColors } from '../constants/colors';

export interface IUnderlinedLink extends Omit<NavLinkProps, 'href'> {
	asStandardLink?: boolean;
	focusColor?: string;
	color?: string;
}

const SSLink = styled.a<{ isActive: boolean }>`
	transition: 300ms all ease-in-out;
	font-family: ${Metric.semiBold.name};
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-weight: 500;
	font-size: 20px;
	line-height: 1.25;
	color: ${(props) => (props.isActive ? PrimaryColors.red : PrimaryColors.black)};
	position: relative;
	text-decoration: none;

	&:after {
		content: '';
		transition: 300ms all ease-in-out;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 2px;
		background: ${PrimaryColors.red};
		opacity: ${(props) => `${+props.isActive}`};
	}

	&:hover {
		&:after {
			opacity: 1;
		}
	}

	&:focus,
	&:active {
		outline: none;
		color: ${PrimaryColors.red};
	}
`;

const SLink = styled(BaseNavLink)<{ isActive: boolean }>`
	transition: 300ms all ease-in-out;
	font-family: ${Metric.medium.name};
	-webkit-font-smoothing: antialiased;
	font-style: normal;
	font-weight: 400;
	font-size: 20px;
	line-height: 1.25;
	color: ${(props) => (props.isActive ? PrimaryColors.red : PrimaryColors.black)};
	position: relative;
	text-decoration: none;

	&:after {
		content: '';
		transition: 300ms all ease-in-out;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 2px;
		background: ${PrimaryColors.red};
		opacity: ${(props) => `${+props.isActive}`};
	}

	&:hover {
		&:after {
			opacity: 1;
		}
	}

	&:focus,
	&:active {
		outline: none;
		color: ${PrimaryColors.red};
	}
`;

const UnderlinedLink = React.forwardRef(
	({ asStandardLink = false, color, focusColor, to, ...props }: IUnderlinedLink, ref) => {
		const [isActive, setIsActive] = React.useState(false);

		return asStandardLink ? (
			// @ts-ignore
			<SSLink ref={ref} isActive={isActive} {...props} href={to} className={props.className} />
		) : (
			<SLink
				// @ts-ignore
				ref={ref}
				isActive={isActive}
				{...props}
				to={to}
				className={props.className}
				style={({ isActive: isPropActive }) => {
					// eslint-disable-next-line react-hooks/rules-of-hooks
					useEffect(() => {
						setIsActive(isPropActive);
					}, [isPropActive]);

					return { ...props.style };
				}}
			/>
		);
	}
);

export default UnderlinedLink;
