import { AxiosInstance } from 'axios';
import { IGenericObject, IHttpEntry } from '../../constants/types';
import { wpNonce } from '../../constants/httpClients';

class Page implements IHttpEntry {
	client: AxiosInstance;

	addJsonAfter: boolean;

	constructor(client: AxiosInstance, addJsonAfter: boolean = true) {
		this.client = client;
		this.addJsonAfter = addJsonAfter;
	}

	// eslint-disable-next-line class-methods-use-this
	get headers(): IGenericObject {
		return {
			'X-WP-Nonce': wpNonce,
		};
	}

	getItem = async (id: string | number): Promise<{ [key: string]: any; id: string | number }> => {
		const hasFinalSlash = `${id}`.slice(-1)[0] === '/' || !this.addJsonAfter;
		const urlAppendix = this.addJsonAfter ? 'json-data/' : '';

		const { data } = await this.client.get(`${id}${hasFinalSlash ? '' : '/'}${urlAppendix}`, {
			headers: this.headers,
		});

		return {
			id,
			...data,
		};
	};

	getItems = async (ids: string[] | number[]): Promise<{ [key: string]: any; id: string | number }[]> => {
		const urlData = await Promise.all(ids.map((id) => this.getItem(id)));

		return urlData.map((item: object) => ({
			id: 0,
			...item,
		}));
	};
}

export default Page;
