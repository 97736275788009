export const xs: number = 374;
export const sm: number = 767;
export const md: number = 1023;
export const xl: number = 1439;
export const xxl: number = 1920;

export const defaultBreakpoints = [xxl, xl, md, sm, xs];

export class MediaQuery {
	queries = {
		[xs]: [''],
		[sm]: [''],
		[md]: [''],
		[xl]: [''],
		[xxl]: [''],
	};

	get value() {
		return defaultBreakpoints
			.map((key) =>
				!this.queries[key].length ? '' : `@media (max-width: ${key}px) {${this.queries[key].join('\n').trim()}}`
			)
			.filter((item) => !!item)
			.join('\n')
			.trim();
	}

	addQuery = (bp: number, content: string) => {
		if (!this.queries[bp]) {
			return this;
		}

		this.queries[bp].push(content);

		return this;
	};

	xs = (content: string) => {
		return this.addQuery(xs, content);
	};

	sm = (content: string) => {
		return this.addQuery(sm, content);
	};

	md = (content: string) => {
		return this.addQuery(md, content);
	};

	xl = (content: string) => {
		return this.addQuery(xl, content);
	};

	xxl = (content: string) => {
		return this.addQuery(xxl, content);
	};

	asBreakpointsArray = (property: string, values: string[] | null[]) => {
		values.forEach((value, idx) => {
			if (!value) {
				return;
			}

			this.queries[defaultBreakpoints[idx]].push(`${property}: ${value}`);
		});

		return this;
	};
}
